import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AulaFiltroLista {
  ambienteFiltro: string[];
  setAmbienteFiltro: React.Dispatch<React.SetStateAction<string[]>>;
  professorFiltro: string[];
  setProfessorFiltro: React.Dispatch<React.SetStateAction<string[]>>;
  viewPeriod: string;
  setViewPeriod: React.Dispatch<React.SetStateAction<string>>;
  dataSelecionada: Date;
  setDataSelecionada: React.Dispatch<React.SetStateAction<Date>>;
}

const AulaFiltroListaContext = createContext<AulaFiltroLista | undefined>(
  undefined
);

interface AulaFiltroListaProviderProps {
  children: ReactNode;
}

export const AulaFiltroListaProvider = ({
  children,
}: AulaFiltroListaProviderProps) => {
  const [ambienteFiltro, setAmbienteFiltro] = useState<string[]>([]);
  const [professorFiltro, setProfessorFiltro] = useState<string[]>([]);
  const [viewPeriod, setViewPeriod] = useState<string>('week');
  const [dataSelecionada, setDataSelecionada] = useState<Date>(new Date());

  return (
    <AulaFiltroListaContext.Provider
      value={{
        ambienteFiltro,
        setAmbienteFiltro,
        professorFiltro,
        setProfessorFiltro,
        viewPeriod,
        setViewPeriod,
        dataSelecionada,
        setDataSelecionada,
      }}
    >
      {children}
    </AulaFiltroListaContext.Provider>
  );
};

export function useAulaFiltroLista() {
  const context = useContext(AulaFiltroListaContext);
  if (!context) {
    throw new Error(
      'useAulaFiltroLista deve ser usado dentro de um AulaFiltroListaProvider'
    );
  }
  return context;
}

export default { useAulaFiltroLista };
