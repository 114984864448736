import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/Auth/authService';
import Carregamento from '../../components/Carregamento/Carregamento';
import LoginForm from '../../components/LoginForm/LoginForm';
import HealthService from '../../services/Health/healthService';
import { toast } from 'react-toastify';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageClass, setMessageClass] = useState('');
  const [carregando, setCarregando] = useState(false);

  const navigate = useNavigate();

  const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUsername(e.target.value);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  useEffect(() => {
    const checkHealth = async () => {
      const isHealthy = await HealthService.healthCheck();
      setCarregando(!isHealthy);
    };

    checkHealth();
  }, []);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCarregando(true);
    const result = await AuthService.login(username, password);
    setCarregando(false);

    console.log(result);
    if (result.passwordNeedsUpdate) {
      toast.success('Login realizado com sucesso!');
      toast.info('Necessario atualizar sua senha!');
      navigate('/update-password');
    } else if (result.success && result.passwordNeedsUpdate === false) {
      toast.success('Login realizado com sucesso!');
      setMessage('Login realizado com sucesso!');
      setMessageClass('success');
      navigate('/dashboard');
    } else {
      setMessage(result.message);
      toast.error(result.message);
      setMessageClass('danger');
    }
  };

  if (carregando) {
    return <Carregamento />;
  }

  return (
    <LoginForm
      onUserChange={handleUserChange}
      onPasswordChange={handlePasswordChange}
      onLogin={handleLogin}
      message={message}
      messageClass={messageClass}
    />
  );
}

export default Login;
