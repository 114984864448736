import React from 'react';
import { ModalProps } from '../../interfaces/Modal/ModalProps';
import './Modal.css';

const Modal: React.FC<ModalProps> = ({ isVisible, hideModal, children }) => {
  const handleBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === event.currentTarget) {
      hideModal();
    }
  };

  return (
    <div
      className={`modal ${isVisible ? 'show' : ''}`}
      onClick={handleBackdropClick}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {children}
        <button className="close" onClick={hideModal}>
          X
        </button>
      </div>
    </div>
  );
};

export default Modal;
