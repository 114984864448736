import React from 'react';
import './PasswordForm.css';
import { PasswordFormProps } from '../../interfaces/PasswordForm/PasswordFormProps';

const PasswordForm: React.FC<PasswordFormProps> = ({
  onPasswordChange,
  onPasswordRecheckChange,
  onLogin,
  message,
  messageClass,
}) => {
  return (
    <>
      <ul style={{ margin: 0, padding: 0 }} className="cb-slideshow">
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
      </ul>

      {/* Logo */}
      <img alt="fundo" className="image-logo" src="assets/image-logo.png"></img>

      <div className="login-container">
        <form className="login-form" onSubmit={onLogin}>
          <h3>Atualizar senha</h3>
          <label className="label-username" htmlFor="password">
            Nova Senha
          </label>
          <input
            id="password"
            className="password"
            type="password"
            onChange={onPasswordChange}
            required
          />
          <label className="label-password" htmlFor="password-recheck">
            Digite novamente a Senha
          </label>
          <input
            id="password-recheck"
            type="password"
            className="password-recheck"
            onChange={onPasswordRecheckChange}
            required
          />
          <div className="container-login-button">
            <button type="submit" className="login-button">
              Atualizar
            </button>
          </div>
          {message && (
            <div className={`message ${messageClass}`}>{message}</div>
          )}
        </form>
      </div>
    </>
  );
};

export default PasswordForm;
