import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { useModal } from '../../hooks/useModal';
import { Curso as CursoType } from '../../interfaces/Curso/CursoProps';
import {
  createCurso,
  deleteCurso,
  fetchCurso,
  updateCurso,
} from '../../services/Curso/cursoService';
import { Filtro as FiltroType } from '../../interfaces/Filtro/FiltroProps';
import { fetchFiltro } from '../../services/Filtro/filtroService';
import { useCurso } from '../../context/CursoContext/CursoContext';
import { toast } from 'react-toastify';

const CaiManager: React.FC = () => {
  const modalCai = useModal();
  const modalEdit = useModal();
  const modalCreate = useModal();
  const { cais, setCais } = useCurso();
  const [editCai, setEditCai] = useState<CursoType | null>(null);
  const [newCai, setNewCai] = useState({
    curso: '',
    tempoTotal: '',
    tipo: 'CAI',
    filtro: '',
  });
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [filtros, setFiltros] = useState<FiltroType[]>([]);
  const [caisFiltrados, setCaisFiltrados] = useState<CursoType[]>([]);

  useEffect(() => {
    fetchCurso()
      .then((data) => {
        setCais(data);
        setCaisFiltrados(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar cais:', error);
      });
  }, []);

  useEffect(() => {
    if (modalCai.isVisible || modalEdit.isVisible || modalCreate.isVisible) {
      fetchFiltro().then((data) => {
        setFiltros(data);
      });
    }
  }, [modalCai.isVisible, modalEdit.isVisible, modalCreate.isVisible]);

  useEffect(() => {
    if (!modalCreate.isVisible) {
      setNewCai({
        curso: '',
        tempoTotal: '',
        tipo: 'CAI',
        filtro: '',
      });
    }
  }, [modalCreate.isVisible]);

  useEffect(() => {
    if (needsUpdate) {
      fetchCurso().then((data) => {
        setCais(data);
        setCaisFiltrados(data);
        setNeedsUpdate(false);
      });
    }
  }, [needsUpdate]);

  const handleEditClick = (tecnico: CursoType) => {
    setEditCai(tecnico);
    modalEdit.showModal();
  };

  useEffect(() => {
    if (!modalCai.isVisible && needsUpdate) {
      setNeedsUpdate(false);
      fetchCurso().then((data) => {
        setCais(data);
        setCaisFiltrados(data);
      });
    }
  }, [modalCai.isVisible, needsUpdate]);

  const handleUpdateCai = async (tecnico: CursoType) => {
    try {
      await updateCurso(tecnico._id, tecnico);
      modalEdit.hideModal();
      setEditCai(null);
      setNeedsUpdate(true);
      toast.success('Cai atualizado com sucesso');
    } catch (error) {
      console.error('Erro ao atualizar cai:', error);
      toast.error('Erro ao atualizar cai');
    }
  };

  const handleDeleteCai = async (id: string) => {
    try {
      await deleteCurso(id);
      setNeedsUpdate(true);
      toast.success('Cai deletado com sucesso');
    } catch (error) {
      console.error('Erro ao deletar cai:', error);
      toast.error('Erro ao deletar cai');
    }
  };

  const handleCreateCai = async () => {
    try {
      await createCurso(newCai);
      modalCreate.hideModal();
      setNewCai({
        curso: '',
        tempoTotal: '',
        tipo: 'CAI',
        filtro: '',
      });
      setNeedsUpdate(true);
      toast.success('Cai criado com sucesso');
    } catch (error) {
      console.error('Erro ao criar cai:', error);
      toast.error('Erro ao criar cai');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFiltroId = e.target.value;
    if (editCai) {
      setEditCai({
        ...editCai,
        filtro: newFiltroId,
      });
    }
  };

  const handleNew = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFiltroId = e.target.value;
    if (newCai) {
      setNewCai({
        ...newCai,
        filtro: newFiltroId,
      });
    }
  };

  function searchCais(termoDeBusca: string): CursoType[] {
    return cais.filter(
      (cais) =>
        cais.curso?.toLowerCase().includes(termoDeBusca.toLowerCase()) ||
        cais.tempoTotal?.toLowerCase().includes(termoDeBusca.toLowerCase()) ||
        cais.tipo?.toLowerCase().includes(termoDeBusca.toLowerCase())
    );
  }

  function handleSearch(value: string): void {
    setCaisFiltrados(searchCais(value));
  }

  const decodeCourseName = (name: any) => {
    try {
      return decodeURIComponent(escape(name));
    } catch (e) {
      return name;
    }
  };

  return (
    <>
      <button className="cai-botton" onClick={modalCai.showModal}>
        CAI
      </button>
      <Modal isVisible={modalCai.isVisible} hideModal={modalCai.hideModal}>
        <div className="alignTopModal">
          <h2>Aprendizagem</h2>
          <button className="criador" onClick={modalCreate.showModal}>
            Criar CAI
          </button>
        </div>
        <input
          style={{ width: '90%', marginTop: '10px', padding: '10px' }}
          type="text"
          placeholder="Digite aqui para buscar..."
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div
          className="scroll"
          style={{
            height: '500px',
            overflowY: 'scroll',
            justifyContent: 'space-between',
          }}
        >
          {filtros.length > 0
            ? filtros?.map((filtro) =>
                filtro.destino === 'CAI' ? (
                  <>
                    <h2>{filtro.nome}</h2>
                    {caisFiltrados
                      .slice()
                      .sort((a, b) => a.curso.localeCompare(b.curso))
                      .map((curso) =>
                        curso.tipo === 'CAI' && curso.filtro === filtro.nome ? (
                          <>
                            <div
                              key={curso._id}
                              style={{
                                marginBottom: '20px',
                                display: 'flex',
                                justifyContent: 'space-around',
                              }}
                            >
                              <p
                                style={{ fontSize: '22px', marginLeft: 'auto' }}
                              >
                                {decodeCourseName(curso?.curso)} -{' '}
                                {curso?.tempoTotal}hrs
                              </p>
                              <button
                                className="feriado-botton"
                                style={{
                                  cursor: 'pointer',
                                  width: '50px',
                                  height: '50px',
                                  marginLeft: 'auto',
                                }}
                                onClick={() => handleEditClick(curso)}
                              >
                                Editar
                              </button>
                            </div>
                          </>
                        ) : null
                      )}
                    <hr />
                  </>
                ) : (
                  <></>
                )
              )
            : null}
        </div>
      </Modal>

      <Modal
        isVisible={modalEdit.isVisible}
        hideModal={() => {
          modalEdit.hideModal();
          setNeedsUpdate(true);
        }}
      >
        <h2>Editar CAI</h2>
        {editCai && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateCai(editCai);
            }}
          >
            <p className="title-modal">Nome</p>
            <input
              placeholder="Digite o nome..."
              type="text"
              value={editCai.curso}
              onChange={(e) =>
                setEditCai({
                  ...editCai,
                  curso: e.target.value,
                })
              }
              required
            />
            <p className="title-modal">Tempo Total</p>
            <input
              placeholder="Digite o tempo..."
              type="text"
              value={editCai.tempoTotal}
              onChange={(e) =>
                setEditCai({
                  ...editCai,
                  tempoTotal: e.target.value,
                })
              }
              required
            />
            <p className="title-modal">Tipo do Curso</p>
            <select value={editCai.filtro} onChange={(e) => handleChange(e)}>
              <option></option>
              {filtros.map((filtro) =>
                filtro.destino === 'CAI' ? (
                  <option key={filtro._id} value={filtro.nome}>
                    {filtro.nome} - {filtro.destino}
                  </option>
                ) : null
              )}
            </select>
            <br />
            <button className="editar" type="submit">
              Atualizar CAI
            </button>
            <button
              type="button"
              className="deletar"
              onClick={() => {
                handleDeleteCai(editCai._id);
                modalEdit.hideModal();
              }}
            >
              Deletar CAI
            </button>
          </form>
        )}
      </Modal>

      <Modal
        isVisible={modalCreate.isVisible}
        hideModal={modalCreate.hideModal}
      >
        <h2>Criar Novo CAI</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateCai();
          }}
        >
          <p className="title-modal">Nome Tecnico</p>
          <input
            type="text"
            placeholder="Digite o nome..."
            value={newCai.curso}
            onChange={(e) => setNewCai({ ...newCai, curso: e.target.value })}
            required
          />
          <p className="title-modal">Tempo Total</p>
          <input
            type="number"
            placeholder="Digite o tempo..."
            value={newCai.tempoTotal}
            onChange={(e) =>
              setNewCai({ ...newCai, tempoTotal: e.target.value })
            }
          />
          <p className="title-modal">Tipo do Curso</p>

          <select value={newCai.filtro} onChange={(e) => handleNew(e)}>
            <option></option>
            {filtros.map((filtro) =>
              filtro.destino === 'CAI' ? (
                <option key={filtro._id} value={filtro.nome}>
                  {filtro.nome} - {filtro.destino}
                </option>
              ) : null
            )}
          </select>
          <br />
          <button className="criador" type="submit">
            Criar
          </button>
        </form>
      </Modal>
    </>
  );
};

export default CaiManager;
