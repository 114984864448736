import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Carregamento from '../../components/Carregamento/Carregamento';
import HealthService from '../../services/Health/healthService';
import { toast } from 'react-toastify';
import PasswordForm from '../../components/PasswordForm/PasswordForm';
import {
  getUsuario,
  updateUsuario,
} from '../../services/Usuario/usuarioService';

function UpdatePassword() {
  const [password, setPassword] = useState('');
  const [passwordRecheck, setPasswordRecheck] = useState('');
  const [message, setMessage] = useState('');
  const [messageClass, setMessageClass] = useState('');
  const [carregando, setCarregando] = useState(false);

  const navigate = useNavigate();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);
  const handlePasswordRecheckChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setPasswordRecheck(e.target.value);

  useEffect(() => {
    const checkHealth = async () => {
      const isHealthy = await HealthService.healthCheck();
      setCarregando(!isHealthy);
    };

    checkHealth();
  }, []);

  const handleUpdatePassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== passwordRecheck) {
      setMessage('As senhas não coincidem');
      setMessageClass('danger');
      return;
    }

    setCarregando(true);
    try {
      const userId = sessionStorage.getItem('userId');
      if (!userId) {
        throw new Error('Usuário não encontrado');
      }

      const userToUpdate = await getUsuario(userId);

      await updateUsuario(userId, {
        _id: userId,
        username: userToUpdate.username,
        password,
        unidade: userToUpdate.unidade,
        level: userToUpdate.level,
      });

      toast.success('Senha atualizada com sucesso!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Erro ao atualizar senha:', error);
      toast.error('Erro ao atualizar senha');
      setMessage('Erro ao atualizar senha');
      setMessageClass('danger');
    } finally {
      setCarregando(false);
    }
  };

  if (carregando) {
    return <Carregamento />;
  }

  return (
    <PasswordForm
      onPasswordChange={handlePasswordChange}
      onPasswordRecheckChange={handlePasswordRecheckChange}
      onLogin={handleUpdatePassword}
      message={message}
      messageClass={messageClass}
    />
  );
}

export default UpdatePassword;
