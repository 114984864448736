import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

// Definição de tipos para o valor do contexto
interface AuthContextType {
  redirectToLogin: () => void;
}

// Criação do contexto com um valor padrão que corresponde ao tipo definido
const AuthContext = React.createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

function AuthProvider({ children }: AuthProviderProps) {
  const navigate = useNavigate();

  const redirectToLogin = () => {
    navigate('/login');
  };

  // Passando um objeto que corresponde ao tipo AuthContextType para o value do Provider
  return (
    <AuthContext.Provider value={{ redirectToLogin }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
