import React, { useEffect, useState } from 'react';
import { useAmbiente } from '../../context/AmbienteContext/AmbienteContext';
import { useFeriado } from '../../context/FeriadoContext/FeriadoContext';
import { Aula } from '../../interfaces/Aula/AulaProps';
import { useAula } from '../../context/AulaContext/AulaContext';
import verificarQualFeriado from '../../utils/functions/verificarQualFeriado';
import './style.css';
import { useAulaFiltroLista } from '../../context/AulaFiltroLista/AulaFiltroLista';
import isFeriado from '../../utils/functions/isFeriado';
import { fetchAulas } from '../../services/Aula/aulaService';
import { Aula as AulaType } from '../../interfaces/Aula/AulaProps';
import ModalAulaManager from '../../components/ModalAulaManager/ModalAulaManager';
import { useModal } from '../../hooks/useModal';

function Calendario() {
  const { ambientes } = useAmbiente();
  const { feriados } = useFeriado();
  const { aulas, setAulas } = useAula();

  const modalEditar = useModal();
  const [editAula, setEditAula] = useState<AulaType | null>(null);

  const { professorFiltro, ambienteFiltro, viewPeriod, dataSelecionada } =
    useAulaFiltroLista();

  const diasDaSemana = ['seg', 'ter', 'quar', 'quin', 'sex', 'sab'];
  const periodos = ['manha', 'tarde', 'noite'];

  const handleEditClick = (aula: AulaType) => {
    setEditAula(aula);
    modalEditar.showModal();
  };

  useEffect(() => {
    fetchAulas()
      .then((data) => {
        setAulas(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar aulas:', error);
      });
  }, [setAulas]);

  function getViewPeriodLength(viewPeriod: string) {
    switch (viewPeriod) {
      case 'week':
        return 1;
      case 'month':
        return 4;
      case 'semester':
        return 26;
      case 'anual':
        return 52;
      default:
        return 1;
    }
  }

  function isAulaNoDia(aula: Aula, data: string): boolean {
    const [ano, mes, dia] = aula.data.split('-').map(Number);
    const diaAula = new Date(ano, mes - 1, dia).toLocaleDateString();
    const dataFormatada = new Date(data).toLocaleDateString();
    return diaAula === dataFormatada;
  }

  return (
    <>
      <div className="filtroDia">
        {periodos.map((periodo) => (
          <div key={periodo} className={`${periodo}Container`}>
            <h2>{periodo.charAt(0).toUpperCase() + periodo.slice(1)}</h2>
            <div className="semanasContainer">
              {Array.from(
                { length: getViewPeriodLength(viewPeriod) },
                (_, weekIndex) => (
                  <table key={weekIndex}>
                    <thead>
                      <tr>
                        <th className="sala-header">Sala</th>
                        {Array.from({ length: 6 }, (_, index) => {
                          const data = new Date(dataSelecionada);
                          if (index === 5) {
                            data.setDate(
                              data.getDate() - data.getDay() + 6 + weekIndex * 7
                            );
                          } else {
                            data.setDate(
                              data.getDate() -
                                data.getDay() +
                                1 +
                                index +
                                weekIndex * 7
                            );
                          }

                          const diaDaSemana = [
                            'Seg',
                            'Ter',
                            'Qua',
                            'Qui',
                            'Sex',
                            'Sab',
                          ][index];

                          const hoje = new Date();

                          const isToday =
                            data.getDate() === hoje.getDate() &&
                            data.getMonth() === hoje.getMonth() &&
                            data.getFullYear() === hoje.getFullYear();

                          return (
                            <th
                              className={`dia ${isToday ? 'today' : ''}`}
                              key={diaDaSemana}
                            >
                              <p>{diaDaSemana}</p>
                              <p>{data.toLocaleDateString()}</p>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {ambientes
                        .slice()
                        .sort((a, b) => {
                          const posicaoA = a.posicao
                            ? parseInt(a.posicao, 10)
                            : Infinity;
                          const posicaoB = b.posicao
                            ? parseInt(b.posicao, 10)
                            : Infinity;
                          return posicaoA - posicaoB;
                        })
                        .filter((sala) =>
                          ambienteFiltro.length > 0
                            ? ambienteFiltro.includes(sala.ambiente)
                            : true
                        )
                        .map((sala) => (
                          <tr key={sala._id}>
                            {Array.from({ length: 6 }, (_, index) => {
                              const data = new Date(dataSelecionada);
                              data.setHours(12, 0, 0, 0); // Definindo o horário para meio-dia
                              if (index === 5) {
                                // Checando se é sábado
                                data.setDate(
                                  data.getDate() -
                                    data.getDay() +
                                    6 +
                                    weekIndex * 7
                                );
                              } else {
                                data.setDate(
                                  data.getDate() -
                                    data.getDay() +
                                    1 +
                                    index +
                                    weekIndex * 7
                                );
                              }

                              const aulasNoDia = aulas.filter(
                                (aula) =>
                                  aula[diasDaSemana[index]] &&
                                  (aula.periodo === periodo ||
                                    (aula.periodo === 'integral' &&
                                      (periodo === 'manha' ||
                                        periodo === 'tarde'))) &&
                                  isAulaNoDia(aula, data + '') &&
                                  aula.sala === sala.ambiente &&
                                  (professorFiltro.length > 0
                                    ? professorFiltro.includes(aula.professor)
                                    : true) &&
                                  !isFeriado(data, feriados)
                              );

                              return (
                                <React.Fragment key={index}>
                                  {index === 0 && (
                                    <td className="sala">{sala.ambiente}</td>
                                  )}
                                  <td
                                    className="professor"
                                    style={{
                                      borderLeft: '1px solid black',
                                      borderRight: '1px solid black',
                                    }}
                                  >
                                    {isFeriado(data, feriados) ? (
                                      <div
                                        style={{
                                          backgroundColor: 'yellow',
                                          height: '100%',
                                          padding: '0px',
                                          margin: '0px',
                                          fontWeight: 'bold',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          alignContent: 'center',
                                        }}
                                      >
                                        {verificarQualFeriado(data, feriados)}
                                      </div>
                                    ) : (
                                      (() => {
                                        if (aulasNoDia.length > 0) {
                                          return aulasNoDia.map(
                                            (aula, index) => (
                                              <div
                                                key={index}
                                                data-id={aula._id}
                                                onClick={() =>
                                                  handleEditClick(aula)
                                                }
                                                className={`tooltip ${aula.curso}`}
                                                style={{
                                                  cursor: 'pointer',
                                                  border: '1px solid black',
                                                }}
                                              >
                                                {aula.professor}
                                                <span className="tooltiptext">
                                                  <p>Informações</p>
                                                  <hr />
                                                  Professor: {
                                                    aula.professor
                                                  }{' '}
                                                  <br />
                                                  Curso: {aula.titulo} <br />
                                                  Tipo: {aula.curso} <br />
                                                  Sala: {aula.sala} <br />
                                                  Periodo: {
                                                    aula.periodo
                                                  } <br /> <hr />
                                                  Inicio:{' '}
                                                  {new Date(
                                                    Number(
                                                      aula.inicio.split('-')[0]
                                                    ),
                                                    Number(
                                                      aula.inicio.split('-')[1]
                                                    ) - 1,
                                                    Number(
                                                      aula.inicio.split('-')[2]
                                                    )
                                                  ).toLocaleDateString(
                                                    'pt-br'
                                                  )}{' '}
                                                  <br />
                                                  Fim:{' '}
                                                  {new Date(
                                                    Number(
                                                      aula.fim.split('-')[0]
                                                    ),
                                                    Number(
                                                      aula.fim.split('-')[1]
                                                    ) - 1,
                                                    Number(
                                                      aula.fim.split('-')[2]
                                                    )
                                                  ).toLocaleDateString(
                                                    'pt-br'
                                                  )}{' '}
                                                  <br />
                                                  <hr />
                                                </span>
                                              </div>
                                            )
                                          );
                                        } else {
                                          return <>Sala Livre</>;
                                        }
                                      })()
                                    )}
                                  </td>
                                </React.Fragment>
                              );
                            })}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )
              )}
            </div>
          </div>
        ))}
      </div>

      {editAula && (
        <ModalAulaManager
          aula={editAula}
          isVisible={modalEditar.isVisible}
          hideModal={modalEditar.hideModal}
          clear={false}
        />
      )}
    </>
  );
}

export default Calendario;
