import axios from 'axios';
import { server } from './api';
import { toast } from 'react-toastify';

const axiosInstance = axios.create({
  baseURL: server,
});

let isNotificationShown = false;

axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      if (!isNotificationShown) {
        isNotificationShown = true;
        window.dispatchEvent(new CustomEvent('unauthorized'));
        toast.error('Acesso negado. Redirecionando para login...');

        // Reseta o estado depois de um tempo para permitir futuras notificações
        setTimeout(() => {
          isNotificationShown = false;
        }, 5000); // 5 segundos, ajuste conforme necessário
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
