import axiosInstance from '../../utils/axiosConfig';
import { Filtro as FiltroType } from '../../interfaces/Filtro/FiltroProps';

export const fetchFiltro = async (): Promise<FiltroType[]> => {
  const response = await axiosInstance.get(`/filtro/`);
  return response.data.filtro;
};

export const createFiltro = async (filtro: {
  nome: string;
  destino: string;
}) => {
  await axiosInstance.post(`/filtro/`, filtro);
};

export const updateFiltro = async (id: string, filtro: FiltroType) => {
  await axiosInstance.put(`/filtro/${id}`, filtro);
};

export const deleteFiltro = async (id: string) => {
  await axiosInstance.delete(`/filtro/${id}`);
};
