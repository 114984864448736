import { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { useModal } from '../../hooks/useModal';
import {
  adiarAula,
  aulasUnicas,
  fetchAulas,
} from '../../services/Aula/aulaService';
import { useAula } from '../../context/AulaContext/AulaContext';
import { toast } from 'react-toastify';
import { Aula as AulaType } from '../../interfaces/Aula/AulaProps';

const AdiarTurmaManager: React.FC = () => {
  const modalAdiar = useModal();

  const { setAulas } = useAula();

  const [aulasFiltradas, setAulasFiltradas] = useState<AulaType[]>([]);

  const [aulaId, setAulaId] = useState<string>('');
  const [dataInicio, setDataInicio] = useState<string>();
  const [dataFim, setDataFim] = useState<string>();

  useEffect(() => {
    popularAulasUnicas();
  }, []);

  const popularAulasUnicas = async () => {
    try {
      const data = await aulasUnicas();

      setAulasFiltradas(data.aulas);
    } catch (error) {
      console.error('Erro ao buscar aulas:', error);
    }
  };

  const adiarTurma = async () => {
    if (!(aulaId == null || dataFim == null || dataInicio == null)) {
      try {
        await adiarAula(aulaId, dataInicio, dataFim);

        modalAdiar.hideModal();

        await atualizarAulas();
      } catch (error) {
        console.error('Ocorreu um erro ao adiar a turma:', error);
        toast.error('Ocorreu um erro ao adiar a turma');
      }
    }
  };

  const atualizarAulas = async () => {
    try {
      const data = await fetchAulas();
      setAulas(data);
    } catch (error) {
      console.error('Erro ao buscar aulas:', error);
    }
  };

  const decodeCourseName = (name: any) => {
    try {
      return decodeURIComponent(escape(name));
    } catch (e) {
      return name;
    }
  };

  return (
    <>
      <button className="adiar-botton" onClick={modalAdiar.showModal}>
        Adiar
      </button>
      <Modal isVisible={modalAdiar.isVisible} hideModal={modalAdiar.hideModal}>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <h1>Adiar Turma</h1>
        </div>

        <p style={{ fontSize: '22px' }}>Selecione a turma para Adiar</p>
        <select
          name="filtro-select"
          onChange={(e) => setAulaId(e.target.value)}
          value={aulaId}
          required
        >
          <option>Selecione a turma</option>
          {aulasFiltradas.length > 0 ? (
            aulasFiltradas
              .sort(
                (a, b) =>
                  new Date(b.inicio).getTime() - new Date(a.inicio).getTime()
              )
              .map((aula) => {
                const data = new Date(`${aula.inicio}T00:00:00Z`);

                const dataFormatada = `${data
                  .getUTCDate()
                  .toString()
                  .padStart(2, '0')}/${(data.getUTCMonth() + 1)
                  .toString()
                  .padStart(2, '0')}/${data.getUTCFullYear()}`;

                return (
                  <option key={aula._id} value={aula._id}>
                    {aula.curso} - {dataFormatada} -{' '}
                    {decodeCourseName(aula.titulo)} - {aula.professor} -{' '}
                    {aula.sala} - {aula.periodo}
                  </option>
                );
              })
          ) : (
            <></>
          )}
        </select>

        <br />

        <p style={{ fontSize: '22px' }}>Selecione a data inicio</p>
        <input
          type="date"
          name="dataInicial"
          onChange={(e) => setDataInicio(e.target.value)}
          value={dataInicio}
          style={{ fontSize: '22px' }}
          required
        />

        <br />
        <p style={{ fontSize: '22px' }}>Selecione a data fim</p>
        <input
          type="date"
          name="dataFinal"
          onChange={(e) => setDataFim(e.target.value)}
          value={dataFim}
          style={{ fontSize: '22px' }}
          required
        />

        <br />
        <br />

        <button className="criador" onClick={() => adiarTurma()}>
          Adiar Turma
        </button>
      </Modal>
    </>
  );
};

export default AdiarTurmaManager;
