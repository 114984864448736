import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Professor } from '../../interfaces/Professor/ProfessorProps';

interface ProfessorContextType {
  professores: Professor[];
  setProfessores: React.Dispatch<React.SetStateAction<Professor[]>>;
}

const ProfessorContext = createContext<ProfessorContextType | undefined>(
  undefined
);

interface ProfessorProviderProps {
  children: ReactNode;
}

export const ProfessorProvider = ({ children }: ProfessorProviderProps) => {
  const [professores, setProfessores] = useState<Professor[]>([]);

  return (
    <ProfessorContext.Provider value={{ professores, setProfessores }}>
      {children}
    </ProfessorContext.Provider>
  );
};

export function useProfessor() {
  const context = useContext(ProfessorContext);
  if (!context) {
    throw new Error(
      'useProfessor deve ser usado dentro de um ProfessorProvider'
    );
  }
  return context;
}

export default { useProfessor };
