import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dashboard from '../views/Dashboard/Dashboard';
import Login from '../views/Login/Login';
import UpdatePassword from '../views/UpdatePassword/UpdatePassword';
import Calendario from '../views/Calendario/Calendario';
// import Visualizacao from '../views/Visualizacao/Visualizacao';
// import VisualizacaoCelular from '../views/VisualizacaoCelular/VisualizacaoCelular';
import { AmbienteProvider } from '../context/AmbienteContext/AmbienteContext';
import { FeriadoProvider } from '../context/FeriadoContext/FeriadoContext';
import { AulaProvider } from '../context/AulaContext/AulaContext';
import { ProfessorProvider } from '../context/ProfessorContext/ProfessorContext';
import { AulaFiltroListaProvider } from '../context/AulaFiltroLista/AulaFiltroLista';
import { AuthProvider } from '../provider/AuthProvider/AuthProvider';
import { CursoProvider } from '../context/CursoContext/CursoContext';
import { FiltroProvider } from '../context/FiltroContext/FiltroContext';
import { UsuarioProvider } from '../context/UsuarioContext/UsuarioContext';
import Visualizacao from '../views/Visualizacao/Visualizacao';

function PrivateRoute({ children }) {
  const isAuthenticated = sessionStorage.getItem('token');

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

function RouterConfig() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleUnauthorized = () => {
      navigate('/login');
    };

    window.addEventListener('unauthorized', handleUnauthorized);

    return () => {
      window.removeEventListener('unauthorized', handleUnauthorized);
    };
  }, [navigate]);

  return (
    <AuthProvider>
      <UsuarioProvider>
        <AulaFiltroListaProvider>
          <FiltroProvider>
            <CursoProvider>
              <ProfessorProvider>
                <FeriadoProvider>
                  <AmbienteProvider>
                    <AulaProvider>
                      <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/login" element={<Login />} />
                        <Route
                          path="/update-password"
                          element={
                            <PrivateRoute>
                              <UpdatePassword />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/dashboard"
                          element={
                            <PrivateRoute>
                              <Dashboard />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/visualizacao"
                          element={
                            <PrivateRoute>
                              <Visualizacao />
                            </PrivateRoute>
                          }
                        />
                        {/* <Route path="/phone" element={<VisualizacaoCelular />} /> */}
                      </Routes>
                    </AulaProvider>
                  </AmbienteProvider>
                </FeriadoProvider>
              </ProfessorProvider>
            </CursoProvider>
          </FiltroProvider>
        </AulaFiltroListaProvider>
      </UsuarioProvider>
    </AuthProvider>
  );
}

export default RouterConfig;
