import { Aula as AulaType } from '../../interfaces/Aula/AulaProps';
import axiosInstance from '../../utils/axiosConfig';

export const fetchAulas = async (): Promise<AulaType[]> => {
  const response = await axiosInstance.get(`/aula/`);
  return response.data.aulas;
};

export const createAula = async (aula: {
  sala: string;
  periodo: string;
  professor: string;
  curso: string;
  seg: boolean;
  ter: boolean;
  quar: boolean;
  quin: boolean;
  sex: boolean;
  sab: boolean;
  titulo: string;
  inicio: string;
  fim: string;
}) => {
  const response = await axiosInstance.post(`/aula/`, aula);
  return response.data;
};

export const updateAula = async (id: string, aula: AulaType) => {
  const response = await axiosInstance.put(`/aula/${id}`, aula);
  return response.data;
};

export const deleteAula = async (id: string, todos: boolean) => {
  const response = await axiosInstance.delete(`/aula/${id}`, {
    data: { todos },
  });
  return response.data;
};

export const deleteAllAulas = async (id: string) => {
  const response = await axiosInstance.delete(`/aula/deleteall/${id}`);
  return response.data;
};

export const adiarAula = async (
  id: string,
  novaDataInicio: string,
  novaDataFim: string
) => {
  console.log({
    id,
    novaDataInicio,
    novaDataFim,
  });

  const response = await axiosInstance.post(`/aula/adiar/turma`, {
    id,
    novaDataInicio,
    novaDataFim,
  });
  return response.data;
};

export const aulasUnicas = async () => {
  const response = await axiosInstance.get(`/aula/encontrar/diferente`);
  return response.data;
};
