import axiosInstance from '../../utils/axiosConfig';
import { Feriado as FeriadoType } from '../../interfaces/Feriado/FeriadoProps';

export const fetchFeriados = async (): Promise<FeriadoType[]> => {
  const response = await axiosInstance.get(`/feriado/`);
  return response.data.feriados;
};

export const createFeriado = async (feriado: {
  nome: string;
  data: string;
}) => {
  await axiosInstance.post(`/feriado/`, feriado);
};

export const updateFeriado = async (id: string, feriado: FeriadoType) => {
  await axiosInstance.put(`/feriado/${id}`, feriado);
};

export const deleteFeriado = async (id: string) => {
  await axiosInstance.delete(`/feriado/${id}`);
};
