import Calendario from '../Calendario/Calendario';
import Navbar from '../Navbar/Navbar';
import '../../styles/Navbar/style.css';
import Menu from '../../components/Menu/Menu';
import { useEffect, useState } from 'react';
import { getUsuario } from '../../services/Usuario/usuarioService';

function Dashboard() {
  function logout() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/login';
  }

  const [visaoTelao, setVisaoTelao] = useState(false);

  useEffect(() => {
    async function visaoTela() {
      const user = await getUsuario(sessionStorage.getItem('userId') || '');
      console.log(user.username);
      if (user.username == 'usuarioTelao') {
        setVisaoTelao(true);
      }
    }

    visaoTela();
  }, []);

  return (
    <>
      {visaoTelao === true ? (
        <>
          <button
            style={{ width: '100%', height: '100px', fontSize: '27px' }}
            className="visualizacao"
            onClick={() => (window.location.href = '/visualizacao')}
          >
            Visualização
          </button>
        </>
      ) : (
        <>
          <div className="navbar">
            <img
              src="/assets/logo.png"
              style={{ width: '180px' }}
              alt="logo"
              className="logo"
            />
            <Menu />
            <button className="sair" onClick={() => logout()}>
              Sair
            </button>
          </div>
          <Navbar />
          <Calendario />
        </>
      )}
    </>
  );
}

export default Dashboard;
