import React, { useState, useEffect } from 'react';
import './Carregamento.css';

function Carregamento() {
  const [mensagem, setMensagem] = useState('Estamos carregando...');

  useEffect(() => {
    const mensagens = [
      'Estamos carregando...',
      'Ainda carregando...',
      'Quase lá...',
      'Só mais um pouquinho...',
      'Carregando módulos...',
      'Inicializando recursos...',
      'Preparando a interface...',
      'Verificando conexões...',
      'Carregando dados...',
      'Aprimorando experiência...',
      'Finalizando carregamento...',
    ];

    let index = 0;

    const intervalId = setInterval(() => {
      index = (index + 1) % mensagens.length;
      setMensagem(mensagens[index]);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div id="loadingScreen">
        <div className="spinner"></div>
        <p>{mensagem}</p>
      </div>
    </>
  );
}

export default Carregamento;
