import { useState, useEffect } from 'react';
import Modal from '../../components/Modal/Modal';
import { useModal } from '../../hooks/useModal';
import { Ambiente as AmbienteType } from '../../interfaces/Ambiente/AmbienteProps';
import { useAmbiente } from '../../context/AmbienteContext/AmbienteContext';
import { Aula as AulaType } from '../../interfaces/Aula/AulaProps';
import { Professor as ProfessorType } from '../../interfaces/Professor/ProfessorProps';
import { useProfessor } from '../../context/ProfessorContext/ProfessorContext';
import { useCurso } from '../../context/CursoContext/CursoContext';
import { Curso as CursoType } from '../../interfaces/Curso/CursoProps';
import { useFiltro } from '../../context/FiltroContext/FiltroContext';
import Delorean from '../../components/Delorean/Delorean';
import { toast } from 'react-toastify';
import isFeriado from '../../utils/functions/isFeriado';
import { useFeriado } from '../../context/FeriadoContext/FeriadoContext';
import {
  createAula,
  fetchAulas,
  updateAula,
} from '../../services/Aula/aulaService';
import { useAula } from '../../context/AulaContext/AulaContext';

const AulaManager: React.FC = () => {
  const modalCriar = useModal();
  const modalVortex = useModal();

  const { setAulas } = useAula();

  async function handleCreateAula() {
    try {
      const response = await createAula(newAula);
      modalCriar.hideModal();
      modalVortex.hideModal();
      setNewAula({
        _id: '',
        sala: '',
        periodo: '',
        professor: '',
        curso: '',
        seg: false,
        ter: false,
        quar: false,
        quin: false,
        sex: false,
        sab: false,
        titulo: '',
        idDeCurso: '',
        inicio: '',
        fim: '',
        data: '',
        unidade: '',
      });
      setAmbiente({
        _id: '',
        ambiente: '',
        posicao: '',
        capacidade: '',
      });
      setNewPeriodo({
        nome: '',
        periodo: '',
      });
      setFic({
        _id: '',
        curso: '',
        filtro: '',
        tempoTotal: '',
        tipo: '',
      });
      setProfessor({
        _id: '',
        professor: '',
        tipo: 'adm',
      });
      setNewFiltro({
        destino: '',
        nome: '',
      });
      setNewFiltroUnidade({
        destino: '',
        nome: '',
      });
      atualizarAulas();
      toast.success('Aulas criadas com sucesso');

      // Exibe os alertas retornados pelo backend
      if (response.alertas && response.alertas.length > 0) {
        response.alertas.forEach((alerta: string) => {
          toast.warning(alerta);
        });
      }
    } catch (error) {
      console.error('Erro ao criar a aula:', error);
      toast.error('Erro ao criar a aula');
    }
  }

  const atualizarAulas = async () => {
    try {
      const data = await fetchAulas();
      setAulas(data);
    } catch (error) {
      console.error('Erro ao buscar aulas:', error);
    }
  };

  const { ambientes } = useAmbiente();
  const { professores } = useProfessor();
  const { fics } = useCurso();
  const { filtros } = useFiltro();
  const { feriados } = useFeriado();

  const [ambiente, setAmbiente] = useState<AmbienteType | null>(null);
  const [professor, setProfessor] = useState<ProfessorType | null>(null);
  const [fic, setFic] = useState<CursoType | null>(null);

  const [newAula, setNewAula] = useState<AulaType>({
    _id: '',
    sala: '',
    periodo: '',
    professor: '',
    curso: '',
    seg: false,
    ter: false,
    quar: false,
    quin: false,
    sex: false,
    sab: false,
    titulo: '',
    idDeCurso: '',
    inicio: '',
    fim: '',
    data: '',
    unidade: '',
  });

  useEffect(() => {
    if (ambiente) {
      setNewAula((prevAula) => ({
        ...prevAula,
        sala: ambiente.ambiente,
      }));
    }
  }, [ambiente]);

  useEffect(() => {
    if (professor) {
      setNewAula((prevAula) => ({
        ...prevAula,
        professor: professor.professor,
      }));
    }
  }, [professor]);

  useEffect(() => {
    if (fic) {
      setHoraTotalCurso(Number(fic.tempoTotal));
      setNewAula((prevAula) => ({
        ...prevAula,
        curso: fic.tipo,
        idDeCurso: fic._id,
        titulo: fic.curso,
      }));
    }
  }, [fic]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const nome = e.target.value;
    const selectedAmbiente = ambientes.find((a) => a.ambiente === nome);
    setAmbiente(selectedAmbiente || null);
  };

  const handleChangeProfessor = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const nome = e.target.value;
    const selectedProfessor = professores.find((p) => p.professor === nome);
    setProfessor(selectedProfessor || null);
  };

  const handleChangeFic = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const nome = e.target.value;
    const selectedFic = fics.find((f) => f.curso === nome);
    setFic(selectedFic || null);
  };

  const [newFiltro, setNewFiltro] = useState({ nome: '', destino: '' });
  const [newPeriodo, setNewPeriodo] = useState({ nome: '', periodo: '' });
  const [newFiltroUnidade, setNewFiltroUnidade] = useState({
    nome: '',
    destino: '',
  });

  const destinos = [
    { label: 'FIC', value: 'FIC' },
    { label: 'CAI', value: 'CAI' },
    { label: 'CT', value: 'CT' },
    { label: 'FIC-EXT', value: 'FIC-EXT' },
  ];

  const periodos = [
    { label: 'Manhã', value: 'manha' },
    { label: 'Tarde', value: 'tarde' },
    { label: 'Noite', value: 'noite' },
    { label: 'Integral', value: 'integral' },
  ];

  const handleNew = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newDestino = e.target.value;
    setNewFiltro({
      ...newFiltro,
      destino: newDestino,
    });
  };

  const handleNewFiltroUnidade = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const nome = e.target.value;
    setNewFiltroUnidade({
      ...newFiltroUnidade,
      nome: nome,
    });
  };

  const handleNewPeriodo = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPeriodo_ = e.target.value;
    setNewPeriodo({
      ...newPeriodo,
      periodo: newPeriodo_,
    });
    setNewAula((prevAula) => ({
      ...prevAula,
      periodo: newPeriodo_,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setNewAula((prevAula) => ({
      ...prevAula,
      [name]: checked,
    }));
  };

  const handleDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const { value } = e.target;
    setNewAula((prevAula) => ({
      ...prevAula,
      [field]: value,
    }));
  };

  /* Calculo monstro */
  const handleVortexChange = () => {
    modalVortex.showModal();
  };

  useEffect(() => {
    setSelectedDataInicial(newAula.inicio);
  }, [newAula.inicio]);

  function padWithZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  const [horaTotalCurso, setHoraTotalCurso] = useState<number>(0); // Exemplo de valor inicial
  const [dataCalculada, setDataCalculada] = useState<string | undefined>();

  const [selectedDataInicial, setSelectedDataInicial] = useState<
    string | undefined
  >(newAula.inicio);

  useEffect(() => {
    calcularDataFinal();
    if (
      !newAula.seg &&
      !newAula.ter &&
      !newAula.quar &&
      !newAula.quin &&
      !newAula.sex &&
      !newAula.sab
    ) {
      setDataCalculada(undefined);
    }

    if (!selectedDataInicial) {
      setDataCalculada(undefined);
    }
  }, [
    selectedDataInicial,
    newAula.seg,
    newAula.ter,
    newAula.quar,
    newAula.quin,
    newAula.sex,
    newAula.sab,
  ]);

  const [segunda, setSegunda] = useState<number | string>(4);
  const [terca, setTerca] = useState<number | string>(4);
  const [quarta, setQuarta] = useState<number | string>(4);
  const [quinta, setQuinta] = useState<number | string>(4);
  const [sexta, setSexta] = useState<number | string>(4);
  const [sabado, setSabado] = useState<number | string>(8);

  function calcularDataFinal() {
    if (
      selectedDataInicial &&
      (newAula.seg ||
        newAula.ter ||
        newAula.quar ||
        newAula.quin ||
        newAula.sex ||
        newAula.sab)
    ) {
      const horasPorDia = {
        seg: newAula.seg ? parseFloat(segunda as string) : 0,
        ter: newAula.ter ? parseFloat(terca as string) : 0,
        quar: newAula.quar ? parseFloat(quarta as string) : 0,
        quin: newAula.quin ? parseFloat(quinta as string) : 0,
        sex: newAula.sex ? parseFloat(sexta as string) : 0,
        sab: newAula.sab ? parseFloat(sabado as string) : 0,
      };

      let horasRestantes = horaTotalCurso;

      let dataInicio;
      // if (!selectedDataInicial) {
      /* 

        data dos menus

        const [dia, mes, ano] = dataSelecionada.toLocaleDateString().split("/");
        console.log(Number(dia - 1));
        dataInicio = new Date(`${ano}-${mes}-${dia}`);

        */
      dataInicio = new Date();
      // } else {
      dataInicio = new Date(selectedDataInicial);
      // }

      dataInicio.setHours(12);
      const dateObj = new Date(dataInicio);

      while (horasRestantes > 0) {
        const diaDaSemana = dateObj.getDay();
        const horasDeAulaHoje = Object.values(horasPorDia)[diaDaSemana - 1];

        if (!isFeriado(dateObj, feriados) && horasDeAulaHoje > 0) {
          horasRestantes -= horasDeAulaHoje;
        }

        dateObj.setDate(dateObj.getDate() + 1);
      }

      const dataFinal = dateObj.toISOString().split('T')[0];

      setDataCalculada(
        padWithZero(Number(dataFinal.split('-')[0])) +
          '-' +
          dataFinal.split('-')[1] +
          '-' +
          padWithZero(Number(dataFinal.split('-')[2]) - 1).toString()
      );

      console.log(padWithZero(Number(dataFinal.split('-')[2]) - 1).toString());

      newAula.fim =
        padWithZero(Number(dataFinal.split('-')[0])) +
        '-' +
        dataFinal.split('-')[1] +
        '-' +
        padWithZero(Number(dataFinal.split('-')[2]) - 1).toString();
    }
  }

  const decodeCourseName = (name: any) => {
    try {
      return decodeURIComponent(escape(name));
    } catch (e) {
      return name;
    }
  };

  const sortedFics = fics
    .filter((d) => d.tipo === 'FIC' || d.tipo === 'FIC-EXT')
    .sort((a, b) => a.curso.localeCompare(b.curso));

  const sortedTec = fics
    .filter((d) => d.tipo === 'CT' || d.tipo === 'CAI')
    .sort((a, b) => a.curso.localeCompare(b.curso));

  return (
    <>
      <button
        onClick={modalCriar.showModal}
        className="adicionar-button"
        style={{
          cursor: 'pointer',
        }}
      >
        Adicionar Aula
      </button>
      <Modal isVisible={modalCriar.isVisible} hideModal={modalCriar.hideModal}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateAula();
          }}
        >
          <p className="title-modal">Sala</p>
          <select
            title="Sala"
            onChange={handleChange}
            value={newAula.sala}
            required
          >
            <option></option>
            {ambientes.map((ambiente) => (
              <option key={ambiente._id} value={ambiente.ambiente}>
                {ambiente.ambiente}
              </option>
            ))}
          </select>

          <p className="title-modal">Período</p>
          <select
            title="Periodo"
            required
            value={newPeriodo.periodo}
            onChange={handleNewPeriodo}
          >
            <option></option>
            {periodos.map((d) => (
              <option key={d.value} value={d.value}>
                {d.label}
              </option>
            ))}
          </select>

          <p className="title-modal">Nome do Professor</p>
          <select
            title="Professor"
            onChange={handleChangeProfessor}
            value={newAula.professor}
            required
          >
            <option></option>
            {professores
              .sort((a, b) => a.professor.localeCompare(b.professor))
              .map((professor) => (
                <option key={professor._id} value={professor.professor}>
                  {professor.professor}
                </option>
              ))}
          </select>

          <p className="title-modal">Tipo de Turma</p>
          <select
            title="Tipo de turma"
            required
            value={newFiltro.destino}
            onChange={handleNew}
          >
            <option></option>
            {destinos.map((d) => (
              <option key={d.value} value={d.value}>
                {d.label}
              </option>
            ))}
          </select>

          {newFiltro.destino === 'FIC' || newFiltro.destino === 'FIC-EXT' ? (
            <>
              <p className="title-modal">Selecione o Curso</p>
              <select
                title="Curso"
                required
                value={fic?.curso}
                onChange={handleChangeFic}
              >
                <option></option>
                {sortedFics.map((d) => (
                  <option key={d._id} value={d.curso}>
                    {decodeCourseName(d.curso)} - {d.tempoTotal}hrs
                  </option>
                ))}
              </select>
            </>
          ) : (
            <>
              <p className="title-modal">Selecione o Filtro</p>
              <select
                title="Filtro"
                required
                value={newFiltroUnidade.nome}
                onChange={handleNewFiltroUnidade}
                disabled={!newFiltro.destino}
              >
                <option></option>
                {filtros.map((d) => (
                  <option key={d._id} value={d.nome}>
                    {d.nome} - {d.destino}
                  </option>
                ))}
              </select>
              <p className="title-modal">Selecione o Curso</p>
              <select
                title="Curso"
                required
                value={fic?.curso}
                onChange={handleChangeFic}
                disabled={!newFiltro.destino}
              >
                <option></option>
                {sortedTec.map((d) =>
                  d.filtro == newFiltroUnidade.nome ? (
                    <option key={d._id} value={d.curso}>
                      {decodeCourseName(d.curso)} - {d.tempoTotal}hrs
                    </option>
                  ) : null
                )}
              </select>
            </>
          )}
          <br />
          <div className="data-dia">
            <span className="data-dita-span">Segunda</span>
            <input
              title="Segunda"
              className="dia-data"
              type="checkbox"
              name="seg"
              checked={newAula.seg}
              onChange={handleCheckboxChange}
              disabled={!newFiltro.destino}
            />
          </div>
          <div className="data-dia">
            <span className="data-dita-span">Terça</span>
            <input
              title="Terca"
              className="dia-data"
              type="checkbox"
              name="ter"
              checked={newAula.ter}
              onChange={handleCheckboxChange}
              disabled={!newFiltro.destino}
            />
          </div>
          <div className="data-dia">
            <span className="data-dita-span">Quarta</span>
            <input
              title="Quarta"
              className="dia-data"
              type="checkbox"
              name="quar"
              checked={newAula.quar}
              onChange={handleCheckboxChange}
              disabled={!newFiltro.destino}
            />
          </div>
          <div className="data-dia">
            <span className="data-dita-span">Quinta</span>
            <input
              title="Quinta"
              className="dia-data"
              type="checkbox"
              name="quin"
              checked={newAula.quin}
              onChange={handleCheckboxChange}
              disabled={!newFiltro.destino}
            />
          </div>
          <div className="data-dia">
            <span className="data-dita-span">Sexta</span>
            <input
              title="Sexta"
              className="dia-data"
              type="checkbox"
              name="sex"
              checked={newAula.sex}
              onChange={handleCheckboxChange}
              disabled={!newFiltro.destino}
            />
          </div>
          <div className="data-dia">
            <span className="data-dita-span">Sábado</span>
            <input
              title="Sabado"
              className="dia-data"
              type="checkbox"
              name="sab"
              checked={newAula.sab}
              onChange={handleCheckboxChange}
              disabled={!newFiltro.destino}
            />
          </div>

          <p className="title-modal">Data Inicial</p>
          <input
            title="Data inicial"
            type="date"
            value={newAula.inicio}
            onChange={(e) => handleDateChange(e, 'inicio')}
            required
            disabled={!newFiltro.destino}
          />

          <p className="title-modal">Data Final</p>
          <input
            title="Data final"
            type="date"
            value={newAula.fim}
            onChange={(e) => handleDateChange(e, 'fim')}
            required
            disabled={!newFiltro.destino}
          />
          <br />
          <span style={{ color: 'red' }}>
            {newAula.fim !== dataCalculada &&
            newAula.inicio !== undefined &&
            newAula.inicio !== ''
              ? 'Cuidado ao definir as proprias datas! O Delorean calcula o futuro com precisão 👀.'
              : ''}
          </span>
          <br />
          <br />
          <button
            className="criador"
            type="submit"
            disabled={!newFiltro.destino}
          >
            Criar
          </button>
        </form>

        <Delorean
          dataFinal={dataCalculada}
          onVortexChange={handleVortexChange}
        />
      </Modal>

      <Modal
        isVisible={modalVortex.isVisible}
        hideModal={modalVortex.hideModal}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            toast.success('Horários atualizados com sucesso!');
            modalVortex.hideModal();
          }}
        >
          <div>
            <p className="title-modal">Segunda:</p>
            <input
              title="Segunda"
              type="text"
              value={segunda}
              onChange={(e) => setSegunda(e.target.value)}
            />
          </div>
          <div>
            <p className="title-modal">Terça:</p>
            <input
              title="Terca"
              type="text"
              value={terca}
              onChange={(e) => setTerca(e.target.value)}
            />
          </div>
          <div>
            <p className="title-modal">Quarta:</p>
            <input
              title="Quarta"
              type="text"
              value={quarta}
              onChange={(e) => setQuarta(e.target.value)}
            />
          </div>
          <div>
            <p className="title-modal">Quinta:</p>
            <input
              title="Quinta"
              type="text"
              value={quinta}
              onChange={(e) => setQuinta(e.target.value)}
            />
          </div>
          <div>
            <p className="title-modal">Sexta:</p>
            <input
              title="Sexta"
              type="text"
              value={sexta}
              onChange={(e) => setSexta(e.target.value)}
            />
          </div>
          <div>
            <p className="title-modal">Sábado:</p>
            <input
              title="Sabado"
              type="text"
              value={sabado}
              onChange={(e) => setSabado(e.target.value)}
            />
          </div>
          <br />
          <button className="adicionar-button" type="submit">
            Atualizar Horários
          </button>
        </form>
      </Modal>
    </>
  );
};

export default AulaManager;
