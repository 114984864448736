import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Curso } from '../../interfaces/Curso/CursoProps';

interface CursoContextType {
  tecnicos: Curso[];
  setTecnicos: React.Dispatch<React.SetStateAction<Curso[]>>;
  fics: Curso[];
  setFics: React.Dispatch<React.SetStateAction<Curso[]>>;
  cais: Curso[];
  setCais: React.Dispatch<React.SetStateAction<Curso[]>>;
}

const CursoContext = createContext<CursoContextType | undefined>(undefined);

interface CursoProviderProps {
  children: ReactNode;
}

export const CursoProvider = ({ children }: CursoProviderProps) => {
  const [tecnicos, setTecnicos] = useState<Curso[]>([]);
  const [fics, setFics] = useState<Curso[]>([]);
  const [cais, setCais] = useState<Curso[]>([]);

  return (
    <CursoContext.Provider
      value={{ tecnicos, setTecnicos, fics, setFics, cais, setCais }}
    >
      {children}
    </CursoContext.Provider>
  );
};

export function useCurso() {
  const context = useContext(CursoContext);
  if (!context) {
    throw new Error('useCurso deve ser usado dentro de um CursoProvider');
  }
  return context;
}

export default { useCurso };
