import { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { useModal } from '../../hooks/useModal';
import { Ambiente as AmbienteType } from '../../interfaces/Ambiente/AmbienteProps';
import { useAmbiente } from '../../context/AmbienteContext/AmbienteContext';
import {
  createAmbiente,
  deleteAmbiente,
  fetchAmbientes,
  updateAmbiente,
} from '../../services/Ambiente/ambienteService';
import { toast } from 'react-toastify';

const AmbienteManager: React.FC = () => {
  const { ambientes, setAmbientes } = useAmbiente();

  useEffect(() => {
    fetchAmbientes()
      .then((data) => {
        setAmbientes(data);
        setAmbienteFiltrados(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar ambientes:', error);
      });
  }, []);

  const modalAmbiente = useModal();
  const modalEdit = useModal();
  const modalCreate = useModal();

  const [editAmbiente, setEditAmbiente] = useState<AmbienteType | null>(null);

  const [newAmbiente, setNewAmbiente] = useState({
    ambiente: '',
    capacidade: '',
    posicao: '',
  });
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [ambientesFiltrados, setAmbienteFiltrados] = useState<AmbienteType[]>(
    []
  );

  useEffect(() => {
    if (needsUpdate) {
      fetchAmbientes().then((data) => {
        setAmbientes(data);
        setAmbienteFiltrados(data);
        setNeedsUpdate(false);
      });
    }
  }, [needsUpdate]);

  useEffect(() => {
    if (!modalAmbiente.isVisible && needsUpdate) {
      setNeedsUpdate(false);
      fetchAmbientes().then(setAmbientes, setAmbienteFiltrados);
    }
  }, [modalAmbiente.isVisible, needsUpdate]);

  useEffect(() => {
    if (modalAmbiente.isVisible || needsUpdate) {
      fetchAmbientes().then(setAmbientes, setAmbienteFiltrados);
    }
  }, [modalAmbiente.isVisible, needsUpdate]);

  const handleEditClick = (ambiente: AmbienteType) => {
    setEditAmbiente(ambiente);
    modalEdit.showModal();
  };

  const handleUpdateAmbiente = async (ambiente: AmbienteType) => {
    try {
      await updateAmbiente(ambiente._id, ambiente);
      modalEdit.hideModal();
      setEditAmbiente({ _id: '', ambiente: '', capacidade: '', posicao: '' });
      setNeedsUpdate(true);
      toast.success('Ambiente atualizado com sucesso');
    } catch (error) {
      console.error('Erro ao atualizar ambiente:', error);
      toast.error('Erro ao atualizar ambiente');
    }
  };

  const handleDeleteAmbiente = async (id: string) => {
    try {
      await deleteAmbiente(id);
      setNeedsUpdate(true);
      toast.success('Ambiente deletado com sucesso');
    } catch (error) {
      console.error('Erro ao deletar ambiente:', error);
      toast.error('Erro ao deletar ambiente');
    }
  };

  const handleCreateAmbiente = async () => {
    try {
      await createAmbiente(newAmbiente);
      modalCreate.hideModal();
      setNewAmbiente({
        ambiente: '',
        capacidade: '',
        posicao: '',
      });
      setNeedsUpdate(true);
      toast.success('Ambiente criado com sucesso');
    } catch (error) {
      console.error('Erro ao criar ambiente:', error);
      toast.error('Erro ao criar ambiente');
    }
  };

  function searchAmbientes(termoDeBusca: string): AmbienteType[] {
    return ambientes.filter(
      (ambiente) =>
        ambiente.ambiente.toLowerCase().includes(termoDeBusca.toLowerCase()) ||
        ambiente.capacidade.toLowerCase().includes(termoDeBusca.toLowerCase())
    );
  }

  function handleSearch(value: string): void {
    setAmbienteFiltrados(searchAmbientes(value));
  }

  return (
    <>
      <button className="ambiente-botton" onClick={modalAmbiente.showModal}>
        Ambiente
      </button>
      <Modal
        isVisible={modalAmbiente.isVisible}
        hideModal={modalAmbiente.hideModal}
      >
        <div className="alignTopModal">
          <h2>Ambientes</h2>
          <button className="criador" onClick={modalCreate.showModal}>
            Criar Ambiente
          </button>
        </div>
        <input
          style={{ width: '90%', marginTop: '10px', padding: '10px' }}
          type="text"
          placeholder="Digite aqui para buscar..."
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div
          className="scroll"
          style={{
            height: '500px',
            overflowY: 'scroll',
          }}
        >
          {ambientesFiltrados
            .slice()
            .sort((a, b) => a.ambiente.localeCompare(b.ambiente))
            .map((ambiente) => (
              <div
                key={ambiente._id}
                style={{
                  marginBottom: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p style={{ fontSize: '22px', marginLeft: '40px' }}>
                  {ambiente.ambiente}, Capacidade: {ambiente.capacidade},
                  Posição: {ambiente.posicao}
                </p>
                <div>
                  <button
                    className="feriado-botton"
                    style={{
                      cursor: 'pointer',
                      width: '50px',
                      height: '50px',
                      marginRight: '20px',
                      textAlign: 'center',
                    }}
                    onClick={() => handleEditClick(ambiente)}
                  >
                    Editar
                  </button>
                </div>
              </div>
            ))}
        </div>
      </Modal>

      <Modal
        isVisible={modalEdit.isVisible}
        hideModal={() => {
          modalEdit.hideModal();
          setNeedsUpdate(true);
        }}
      >
        <h2>Editar Ambiente</h2>
        {editAmbiente && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateAmbiente(editAmbiente);
            }}
          >
            <p className="title-modal">Nome Ambiente</p>
            <input
              title=""
              placeholder="Digite o nome do ambiente"
              type="text"
              value={editAmbiente.ambiente}
              onChange={(e) =>
                setEditAmbiente({
                  ...editAmbiente,
                  ambiente: e.target.value,
                })
              }
              required
            />
            <p className="title-modal">Capacidade Ambiente</p>
            <input
              title=""
              placeholder="Digite a capacidade do ambiente"
              type="number"
              value={editAmbiente.capacidade}
              onChange={(e) =>
                setEditAmbiente({
                  ...editAmbiente,
                  capacidade: e.target.value,
                })
              }
              required
            />
            <p className="title-modal">Posição no Calendário:</p>
            <input
              type="number"
              value={editAmbiente.posicao}
              placeholder="Digite a posição..."
              onChange={(e) =>
                setEditAmbiente({ ...editAmbiente, posicao: e.target.value })
              }
              required
            />
            <br />
            <button className="editar" type="submit">
              Atualizar Ambiente
            </button>
            <button
              type="button"
              className="deletar"
              onClick={() => {
                handleDeleteAmbiente(editAmbiente._id);
                modalEdit.hideModal();
              }}
            >
              Deletar Ambiente
            </button>
          </form>
        )}
      </Modal>

      <Modal
        isVisible={modalCreate.isVisible}
        hideModal={modalCreate.hideModal}
      >
        <h2>Criar Novo Ambiente</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateAmbiente();
          }}
        >
          <p className="title-modal">Nome Ambiente:</p>
          <input
            type="text"
            value={newAmbiente.ambiente}
            onChange={(e) =>
              setNewAmbiente({ ...newAmbiente, ambiente: e.target.value })
            }
            placeholder="Digite o nome..."
            required
          />
          <p className="title-modal">Capacidade Ambiente:</p>
          <input
            type="number"
            value={newAmbiente.capacidade}
            placeholder="Digite a capacidade..."
            onChange={(e) =>
              setNewAmbiente({ ...newAmbiente, capacidade: e.target.value })
            }
            required
          />
          <p className="title-modal">Posição no Calendário:</p>
          <input
            type="number"
            value={newAmbiente.posicao}
            placeholder="Digite a posição..."
            onChange={(e) =>
              setNewAmbiente({ ...newAmbiente, posicao: e.target.value })
            }
            required
          />
          <br />
          <button className="criador" type="submit">
            Criar
          </button>
        </form>
      </Modal>
    </>
  );
};

export default AmbienteManager;
