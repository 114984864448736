import axiosInstance from '../../utils/axiosConfig';
import { Curso as CursoType } from '../../interfaces/Curso/CursoProps';

export const fetchCurso = async (): Promise<CursoType[]> => {
  const response = await axiosInstance.get(`/curso/`);
  return response.data.cursos;
};

export const createCurso = async (curso: {
  curso: string;
  tempoTotal: string;
  tipo: string;
  filtro: string;
}) => {
  await axiosInstance.post(`/curso/`, curso);
};

export const updateCurso = async (id: string, curso: CursoType) => {
  await axiosInstance.put(`/curso/${id}`, curso);
};

export const deleteCurso = async (id: string) => {
  await axiosInstance.delete(`/curso/${id}`);
};
