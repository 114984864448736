import React from 'react';
import './LoginForm.css';
import { LoginFormProps } from '../../interfaces/LoginForm/LoginFormProps';

const LoginForm: React.FC<LoginFormProps> = ({
  onUserChange,
  onPasswordChange,
  onLogin,
  message,
  messageClass,
}) => {
  return (
    <>
      <ul style={{ margin: 0, padding: 0 }} className="cb-slideshow">
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
      </ul>

      {/* Logo */}
      <img alt="fundo" className="image-logo" src="assets/image-logo.png"></img>

      <div className="login-container">
        <form className="login-form" onSubmit={onLogin}>
          <label className="label-username" htmlFor="username">
            Usuário
          </label>
          <input
            id="username"
            className="username"
            type="text"
            onChange={onUserChange}
            required
          />
          <label className="label-password" htmlFor="password">
            Senha
          </label>
          <input
            id="password"
            type="password"
            className="password"
            onChange={onPasswordChange}
            required
          />
          <div className="container-login-button">
            <button type="submit" className="login-button">
              Acessar
            </button>
          </div>
          {message && (
            <div className={`message ${messageClass}`}>{message}</div>
          )}
        </form>
      </div>
    </>
  );
};

export default LoginForm;
