import { AxiosError } from 'axios';
import axiosInstance from '../../utils/axiosConfig';
import { LoginResponse } from '../../interfaces/LoginForm/LoginFormProps';

const AuthService = {
  login: async (username: string, password: string): Promise<LoginResponse> => {
    try {
      const response = await axiosInstance.post(`/user/login`, {
        username,
        password,
      });
      sessionStorage.setItem('token', response.data.token);
      sessionStorage.setItem('userId', response.data.user);
      return {
        success: true,
        message: 'Login realizado!',
        passwordNeedsUpdate: response.data.passwordNeedsUpdate,
      };
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error(axiosError);
      if (axiosError.response?.status === 400) {
        return {
          success: false,
          message: 'Usuário ou Senha Incorreta',
          passwordNeedsUpdate: false,
        };
      } else {
        return {
          success: false,
          message: 'Contate o suporte',
          passwordNeedsUpdate: false,
        };
      }
    }
  },
  isAdmin: async () => {
    try {
      const response = await axiosInstance.post(`/user/admin/isAdmin`);
      return response.status === 200;
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error(axiosError);
      if (axiosError.response?.status === 400) {
        return false;
      } else {
        return false;
      }
    }
  },
};

export default AuthService;
