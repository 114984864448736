import axiosInstance from '../../utils/axiosConfig';
import { Professor as ProfessorType } from '../../interfaces/Professor/ProfessorProps';

export const fetchProfessores = async (): Promise<ProfessorType[]> => {
  const response = await axiosInstance.get(`/professor/`);
  return response.data.aulas;
};

export const createProfessor = async (professor: {
  professor: string;
  tipo: string;
}) => {
  await axiosInstance.post(`/professor/`, professor);
};

export const updateProfessor = async (id: string, professor: ProfessorType) => {
  await axiosInstance.put(`/professor/${id}`, professor);
};

export const deleteProfessor = async (id: string) => {
  await axiosInstance.delete(`/professor/${id}`);
};
