import React from 'react';

function Footer() {
  return (
    <>
      <footer>
        <p>
          Gerenciamento de Ambientes V1.1.0 Desenvolvido na Escola SENAI
          &quot;Ricardo Lerner&quot;. © 2024. Responsável Técnico: Prof. Rosa
        </p>
      </footer>
    </>
  );
}

export default Footer;
