import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { useModal } from '../../hooks/useModal';
import { Curso as CursoType } from '../../interfaces/Curso/CursoProps';
import {
  createCurso,
  deleteCurso,
  fetchCurso,
  updateCurso,
} from '../../services/Curso/cursoService';
import { useCurso } from '../../context/CursoContext/CursoContext';
import { toast } from 'react-toastify';

const FicManager: React.FC = () => {
  const modalFic = useModal();
  const modalEdit = useModal();
  const modalCreate = useModal();
  const { fics, setFics } = useCurso();

  const [editFic, setEditFic] = useState<CursoType | null>(null);
  const [newFic, setNewFic] = useState({
    curso: '',
    tempoTotal: '',
    tipo: 'FIC',
    filtro: 'FIC',
  });
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [ficsFiltrados, setFicsFiltrados] = useState<CursoType[]>([]);

  useEffect(() => {
    fetchCurso()
      .then((data) => {
        setFics(data);
        setFicsFiltrados(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar tecnicos:', error);
      });
  }, []);

  useEffect(() => {
    if (!modalCreate.isVisible) {
      setNewFic({
        curso: '',
        tempoTotal: '',
        tipo: 'FIC',
        filtro: 'FIC',
      });
    }
  }, [modalCreate.isVisible]);

  useEffect(() => {
    if (needsUpdate) {
      fetchCurso().then((data) => {
        setFics(data);
        setFicsFiltrados(data);
        setNeedsUpdate(false);
      });
    }
  }, [needsUpdate]);

  const handleEditClick = (tecnico: CursoType) => {
    setEditFic(tecnico);
    modalEdit.showModal();
  };

  useEffect(() => {
    if (!modalFic.isVisible && needsUpdate) {
      setNeedsUpdate(false);
      fetchCurso().then((data) => {
        setFics(data);
        setFicsFiltrados(data);
      });
    }
  }, [modalFic.isVisible, needsUpdate]);

  const handleUpdateFic = async (tecnico: CursoType) => {
    try {
      await updateCurso(tecnico._id, tecnico);
      modalEdit.hideModal();
      setEditFic(null);
      setNeedsUpdate(true);
      toast.success('Fic atualizado com sucesso');
    } catch (error) {
      console.error('Erro ao atualizar fic:', error);
      toast.error('Erro ao atualizar fic');
    }
  };

  const handleDeleteFic = async (id: string) => {
    try {
      await deleteCurso(id);
      setNeedsUpdate(true);
      toast.success('Fic deletado com sucesso');
    } catch (error) {
      console.error('Erro ao deletar fic:', error);
      toast.error('Erro ao deletar fic');
    }
  };

  const handleCreateFic = async () => {
    try {
      await createCurso(newFic);
      modalCreate.hideModal();
      setNewFic({
        curso: '',
        tempoTotal: '',
        tipo: 'FIC',
        filtro: 'FIC',
      });
      setNeedsUpdate(true);
      toast.success('Fic criado com sucesso');
    } catch (error) {
      console.error('Erro ao criar fic:', error);
      toast.error('Erro ao criar fic');
    }
  };

  function searchFics(termoDeBusca: string): CursoType[] {
    return fics.filter(
      (fic) =>
        fic.curso?.toLowerCase().includes(termoDeBusca.toLowerCase()) ||
        fic.tempoTotal?.toLowerCase().includes(termoDeBusca.toLowerCase()) ||
        fic.tipo?.toLowerCase().includes(termoDeBusca.toLowerCase())
    );
  }

  function handleSearch(value: string): void {
    setFicsFiltrados(searchFics(value));
  }

  const decodeCourseName = (name: any) => {
    try {
      return decodeURIComponent(escape(name));
    } catch (e) {
      return name;
    }
  };

  return (
    <>
      <button className="curso-botton" onClick={modalFic.showModal}>
        FIC
      </button>
      <Modal isVisible={modalFic.isVisible} hideModal={modalFic.hideModal}>
        <div className="alignTopModal">
          <h2>Formação Inicial</h2>
          <button className="criador" onClick={modalCreate.showModal}>
            Criar FIC
          </button>
        </div>
        <input
          style={{ width: '90%', marginTop: '10px', padding: '10px' }}
          type="text"
          placeholder="Digite aqui para buscar..."
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div
          className="scroll"
          style={{ height: '500px', overflowY: 'scroll' }}
        >
          {ficsFiltrados
            .slice()
            .sort((a, b) => a.curso.localeCompare(b.curso))
            .map((curso) =>
              curso.tipo === 'FIC' ? (
                <div
                  key={curso._id}
                  style={{
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                >
                  <p
                    style={{
                      fontSize: '22px',
                      marginLeft: 'auto',
                    }}
                  >
                    {decodeCourseName(curso?.curso)} - {curso?.tempoTotal}hrs
                  </p>
                  <button
                    className="feriado-botton"
                    style={{
                      cursor: 'pointer',
                      width: '50px',
                      height: '50px',
                      marginLeft: 'auto',
                    }}
                    onClick={() => handleEditClick(curso)}
                  >
                    Editar
                  </button>
                </div>
              ) : null
            )}
        </div>
      </Modal>

      <Modal
        isVisible={modalEdit.isVisible}
        hideModal={() => {
          modalEdit.hideModal();
          setNeedsUpdate(true);
        }}
      >
        <h2>Editar Fic</h2>
        {editFic && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateFic(editFic);
            }}
          >
            <p className="title-modal">Nome</p>
            <input
              placeholder="Digite o nome..."
              type="text"
              value={editFic.curso}
              onChange={(e) =>
                setEditFic({
                  ...editFic,
                  curso: e.target.value,
                })
              }
              required
            />
            <p className="title-modal">Tempo Total</p>
            <input
              placeholder="Digite o tempo..."
              type="number"
              value={editFic.tempoTotal}
              onChange={(e) =>
                setEditFic({
                  ...editFic,
                  tempoTotal: e.target.value,
                })
              }
              required
            />
            <br />
            <button className="editar" type="submit">
              Atualizar FIC
            </button>
            <button
              type="button"
              className="deletar"
              onClick={() => {
                handleDeleteFic(editFic._id);
                modalEdit.hideModal();
              }}
            >
              Deletar FIC
            </button>
          </form>
        )}
      </Modal>

      <Modal
        isVisible={modalCreate.isVisible}
        hideModal={modalCreate.hideModal}
      >
        <h2>Criar Novo FIC</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateFic();
          }}
        >
          <p className="title-modal">Nome Técnico</p>
          <input
            type="text"
            placeholder="Digite o nome..."
            value={newFic.curso}
            onChange={(e) => setNewFic({ ...newFic, curso: e.target.value })}
            required
          />
          <p className="title-modal">Tempo Total</p>
          <input
            type="number"
            placeholder="Digite o tempo..."
            value={newFic.tempoTotal}
            onChange={(e) =>
              setNewFic({ ...newFic, tempoTotal: e.target.value })
            }
            required
          />
          <br />
          <button className="criador" type="submit">
            Criar
          </button>
        </form>
      </Modal>
    </>
  );
};

export default FicManager;
