import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import { Aula as AulaType } from '../../interfaces/Aula/AulaProps';
import { useProfessor } from '../../context/ProfessorContext/ProfessorContext';
import { useAmbiente } from '../../context/AmbienteContext/AmbienteContext';
import { useCurso } from '../../context/CursoContext/CursoContext';
import { useFiltro } from '../../context/FiltroContext/FiltroContext';
import { toast } from 'react-toastify';
import { useModal } from '../../hooks/useModal';
import {
  deleteAllAulas,
  deleteAula,
  fetchAulas,
  updateAula,
} from '../../services/Aula/aulaService';
import { useAula } from '../../context/AulaContext/AulaContext';

interface ModalAulaManagerProps {
  aula: AulaType;
  isVisible: boolean;
  hideModal: () => void;
  clear: boolean;
}

const ModalAulaManager: React.FC<ModalAulaManagerProps> = ({
  aula,
  isVisible,
  hideModal,
  clear,
}) => {
  const [editAula, setEditAula] = useState<AulaType>({ ...aula });

  const { professores } = useProfessor();
  const { ambientes } = useAmbiente();
  const { fics } = useCurso();
  const { filtros } = useFiltro();
  const { setAulas } = useAula();

  useEffect(() => {
    setEditAula({ ...aula });
  }, [aula]);

  const editarOpcoesModal = useModal();
  const deletarOpcoesModal = useModal();

  const handleEditarOpcoes = async () => {
    hideModal();
    editarOpcoesModal.showModal();
  };

  const handleDeletarOpcoes = async () => {
    hideModal();
    deletarOpcoesModal.showModal();
  };

  const handleEditAula = async () => {
    try {
      hideModal();
    } catch (error) {
      console.error('Erro ao editar a aula:', error);
      toast.error('Erro ao editar a aula');
    }
  };

  const handleDeleteAula = async (id: string) => {
    try {
      await deleteAula(id, false);
      toast.success('Aula deletada com sucesso!');
      deletarOpcoesModal.hideModal();
      hideModal();

      const response = await fetchAulas();
      setAulas(response);
    } catch (error) {
      toast.error('Erro ao deletar aula');
      console.error('Erro ao deletar aula:', error);
    }
  };

  const handleDeleteAllAulas = async (id: string) => {
    try {
      await deleteAllAulas(id);
      toast.success('Todas as aulas foram deletadas com sucesso!');

      deletarOpcoesModal.hideModal();
      hideModal();

      const response = await fetchAulas();
      setAulas(response);
    } catch (error) {
      toast.error('Erro ao deletar todas as aulas');
      console.error('Erro ao deletar todas as aulas:', error);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setEditAula((prevAula) => ({
      ...prevAula,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setEditAula((prevAula) => ({
      ...prevAula,
      [name]: checked,
    }));
  };

  const handleDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const { value } = e.target;
    setEditAula((prevAula) => ({
      ...prevAula,
      [field]: value,
    }));
  };

  const periodos = [
    { label: 'Manhã', value: 'manha' },
    { label: 'Tarde', value: 'tarde' },
    { label: 'Noite', value: 'noite' },
    { label: 'Integral', value: 'integral' },
  ];

  const destinos = [
    { label: 'FIC', value: 'FIC' },
    { label: 'CAI', value: 'CAI' },
    { label: 'CT', value: 'CT' },
    { label: 'FIC-EXT', value: 'FIC-EXT' },
  ];

  const atualizarAula = async (todos: boolean) => {
    try {
      const aulaAtualizada = { ...editAula, todos };

      const responseAlert = await updateAula(editAula._id, aulaAtualizada);

      toast.success('Aula editada com sucesso!');

      hideModal();
      editarOpcoesModal.hideModal();

      const response = await fetchAulas();
      setAulas(response);

      if (responseAlert.alertas && responseAlert.alertas.length > 0) {
        responseAlert.alertas.forEach((alerta: string) => {
          toast.warning(alerta);
        });
      }
    } catch (error) {
      toast.error('Erro ao atualizar aula');
      console.error('Erro ao atualizar aula:', error);
    }
  };

  const decodeCourseName = (name: any) => {
    try {
      return decodeURIComponent(escape(name));
    } catch (e) {
      return name;
    }
  };

  const sortedFics = fics
    .filter((d) => d.tipo === 'FIC' || d.tipo === 'FIC-EXT')
    .sort((a, b) => a.curso.localeCompare(b.curso));

  const sortedTec = fics
    .filter((d) => d.tipo === 'CT' || d.tipo === 'CAI')
    .sort((a, b) => a.curso.localeCompare(b.curso));

  return (
    <>
      <Modal isVisible={isVisible} hideModal={hideModal}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleEditAula();
          }}
        >
          <p className="title-modal">Sala</p>
          <select
            name="sala"
            value={editAula.sala}
            onChange={handleChange}
            title="Sala"
            required
          >
            <option></option>
            {ambientes.map((ambiente) => (
              <option key={ambiente._id} value={ambiente.ambiente}>
                {ambiente.ambiente}
              </option>
            ))}
          </select>

          <p className="title-modal">Período</p>
          <select
            name="periodo"
            title="Periodo"
            value={editAula.periodo}
            onChange={handleChange}
            required
          >
            <option></option>
            {periodos.map((d) => (
              <option key={d.value} value={d.value}>
                {d.label}
              </option>
            ))}
          </select>

          <p className="title-modal">Nome do Professor</p>
          <select
            name="professor"
            title="Professor"
            value={editAula.professor}
            onChange={handleChange}
            required
          >
            <option></option>
            {professores
              .sort((a, b) => a.professor.localeCompare(b.professor))
              .map((professor) => (
                <option key={professor._id} value={professor.professor}>
                  {professor.professor}
                </option>
              ))}
          </select>

          <p className="title-modal">Tipo de Turma</p>
          <select
            name="curso"
            title="Tipo de Turma"
            value={editAula.curso}
            onChange={handleChange}
            required
          >
            <option></option>
            {destinos.map((d) => (
              <option key={d.value} value={d.value}>
                {d.label}
              </option>
            ))}
          </select>
          {editAula.curso === 'FIC' || editAula.curso === 'FIC-EXT' ? (
            <>
              <p className="title-modal">Selecione o Curso</p>
              <select
                title="Curso"
                name="titulo"
                value={editAula.titulo}
                onChange={handleChange}
                required
              >
                <option></option>
                {sortedFics.map((d) => (
                  <option key={d._id} value={d.curso}>
                    {decodeCourseName(d.curso)} - {d.tempoTotal}hrs
                  </option>
                ))}
              </select>
            </>
          ) : (
            <>
              <p className="title-modal">Selecione o Curso</p>
              <select
                title="Curso"
                name="titulo"
                value={editAula.titulo}
                onChange={handleChange}
                required
              >
                <option></option>
                {sortedTec.map((d) => (
                  <option key={d._id} value={d.curso}>
                    {decodeCourseName(d.curso)} - {d.tempoTotal}hrs
                  </option>
                ))}
              </select>
            </>
          )}

          <div className="data-dia">
            <span className="data-dita-span">Segunda</span>
            <input
              title="Segunda"
              className="dia-data"
              type="checkbox"
              name="seg"
              checked={editAula.seg}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="data-dia">
            <span className="data-dita-span">Terça</span>
            <input
              title="Terca"
              className="dia-data"
              type="checkbox"
              name="ter"
              checked={editAula.ter}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="data-dia">
            <span className="data-dita-span">Quarta</span>
            <input
              title="Quarta"
              className="dia-data"
              type="checkbox"
              name="quar"
              checked={editAula.quar}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="data-dia">
            <span className="data-dita-span">Quinta</span>
            <input
              title="Quinta"
              className="dia-data"
              type="checkbox"
              name="quin"
              checked={editAula.quin}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="data-dia">
            <span className="data-dita-span">Sexta</span>
            <input
              title="Sexta"
              className="dia-data"
              type="checkbox"
              name="sex"
              checked={editAula.sex}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="data-dia">
            <span className="data-dita-span">Sábado</span>
            <input
              title="Sabado"
              className="dia-data"
              type="checkbox"
              name="sab"
              checked={editAula.sab}
              onChange={handleCheckboxChange}
            />
          </div>

          <p className="title-modal">Data Inicial</p>
          <input
            title="Data inicial"
            type="date"
            name="inicio"
            value={editAula.inicio}
            onChange={(e) => handleDateChange(e, 'inicio')}
            required
          />

          <p className="title-modal">Data Final</p>
          <input
            title="Data final"
            type="date"
            name="fim"
            value={editAula.fim}
            onChange={(e) => handleDateChange(e, 'fim')}
            required
          />
          <br />
          <br />
          <button className="editar" onClick={() => handleEditarOpcoes()}>
            Editar Aula
          </button>
          <button className="deletar" onClick={() => handleDeletarOpcoes()}>
            Deletar Aula
          </button>
        </form>
      </Modal>

      <Modal
        isVisible={editarOpcoesModal.isVisible}
        hideModal={editarOpcoesModal.hideModal}
      >
        <h2>Tem certeza ?</h2>
        <br />
        <p style={{ fontSize: '22px' }}>Escolha abaixo uma das opções:</p>
        <br />
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <button className="editar" onClick={() => atualizarAula(false)}>
            Editar único
          </button>
          <button className="editar" onClick={() => atualizarAula(true)}>
            <b>Editar Tudo</b>
          </button>
        </div>
      </Modal>

      <Modal
        isVisible={deletarOpcoesModal.isVisible}
        hideModal={deletarOpcoesModal.hideModal}
      >
        <h2>Tem certeza ?</h2>
        <br />
        <p>Escolha abaixo uma das opções:</p>
        <br />
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <button
            className="deletar"
            onClick={() => handleDeleteAula(editAula._id)}
          >
            Deletar único
          </button>
          <button
            className="deletar"
            onClick={() => handleDeleteAllAulas(editAula._id)}
          >
            <b>Deletar Tudo</b>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ModalAulaManager;
