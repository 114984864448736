import Info from '../../components/Info/Info';
import ProfessorManager from '../../manager/ProfessorManager/ProfessorManager';
import AmbienteManager from '../../manager/AmbienteManager/AmbienteManager';
import FeriadoManager from '../../manager/FeriadoManager/FeriadoManager';
import FiltroManager from '../../manager/FiltroManager/FiltroManager';
import TecnicoManager from '../../manager/TecnicoManager/TecnicoManager';
import CaiManager from '../../manager/CaiManager/CaiManager';
import '../../styles/Buttons/style.css';
import FicManager from '../../manager/FicManager/FicManager';
import AdiarTurmaManager from '../../manager/AdiarTurmaManager/AdiarTurmaManager';
import UserManager from '../../manager/UserManager/UserManager';
import { useEffect, useState } from 'react';
import AuthService from '../../services/Auth/authService';
import Carregamento from '../../components/Carregamento/Carregamento';
import LoginManager from '../../manager/LoginManager/LoginManager';

function Navbar() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const result = await AuthService.isAdmin();
        setIsAdmin(result);
      } catch (error) {
        console.error('Erro ao verificar status de administrador:', error);
      } finally {
        setCarregando(false);
      }
    };

    checkAdminStatus();
  }, []);

  if (carregando) {
    return <Carregamento />;
  }

  return (
    <>
      <div className="navbar-items">
        <div className="MoverBotao">
          <ProfessorManager />
          <AmbienteManager />
          <FeriadoManager />
          <FiltroManager />
          <TecnicoManager />
          <CaiManager />
          <FicManager />
          <AdiarTurmaManager />

          {isAdmin && (
            <>
              <UserManager />
              <LoginManager />
            </>
          )}
        </div>
        <Info />
      </div>
    </>
  );
}

export default Navbar;
