import React from 'react';

export interface Scrap {
  data: string[];
}

const ExampleComponent = ({ scrap }: { scrap: Scrap }) => {
  return (
    <div style={{ textAlign: 'center' }} className="noticias">
      <h1>Veja nossos cursos gratuitos!</h1>
      <p>Um certificado a mais sempre faz bem 👀😎😎</p>

      <div className="carousel">
        {scrap && scrap.data.length > 0 ? (
          scrap.data.map((curso, index) => (
            <div key={index}>
              <p>
                {curso.length > 40 ? `${curso.substring(0, 40)}...` : curso}
              </p>
            </div>
          ))
        ) : (
          <p>Nenhum curso disponível</p>
        )}
      </div>

      <br />
      <br />
    </div>
  );
};

export default ExampleComponent;
