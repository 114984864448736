import { BrowserRouter as Router } from 'react-router-dom';
import RouterConfig from '../router/router';
import '../styles/Main/App.css';
import Footer from '../components/Footer/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <ToastContainer />
      <div className="app-container">
        <div className="content-wrap">
          <Router>
            <RouterConfig />
          </Router>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
