import { useEffect, useState } from 'react';
import './Delorean.css';

interface DeloreanProps {
  dataFinal: string | undefined;
  onVortexChange: () => void;
}

const Delorean: React.FC<DeloreanProps> = ({ dataFinal, onVortexChange }) => {
  const [mensagem, setMensagem] = useState('Acelerando o fluxo do tempo...');

  const handleVortexClick = () => {
    onVortexChange();
  };

  useEffect(() => {
    const mensagens = [
      'Dobrando o continuum espaço-tempo...',
      'Sincronizando relógios atômicos...',
      'Carregando o capacitor de fluxo...',
      'Ajustando coordenadas temporais...',
      'Calculando rotas temporais...',
      'Inicializando salto quântico...',
      'Estabilizando buracos de minhoca...',
      'Verificando paradoxos temporais...',
      'Carregando algoritmos de previsão...',
      'Preparando a máquina do tempo...',
      'Finalizando viagem temporal...',
    ];

    let index = 0;

    const intervalId = setInterval(() => {
      index = (index + 1) % mensagens.length;
      setMensagem(mensagens[index]);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="loadingScreenDelorean">
      {dataFinal ? (
        <>
          <h3 className="titulo-p">Chegamos ao Destino</h3>
          <div id="delorean-parado"></div>
          <p>
            Data Calculada:{' '}
            <b>
              {dataFinal.split('-')[2] +
                '/' +
                dataFinal.split('-')[1] +
                '/' +
                dataFinal.split('-')[0]}
            </b>
          </p>
        </>
      ) : (
        <>
          <h3 className="titulo-p">Delorean do Tempo</h3>
          <div id="delorean"></div>
          <br />
          <br />
          <br />
          <p className="msg-p">{mensagem}</p>
          <br />
          <button className="adicionar-button" onClick={handleVortexClick}>
            Configurar o Vortex
          </button>
        </>
      )}
    </div>
  );
};

export default Delorean;
