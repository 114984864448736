import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Aula } from '../../interfaces/Aula/AulaProps';

interface AulaContextType {
  aulas: Aula[];
  setAulas: React.Dispatch<React.SetStateAction<Aula[]>>;
}

const AulaContext = createContext<AulaContextType | undefined>(undefined);

interface AulaProviderProps {
  children: ReactNode;
}

export const AulaProvider = ({ children }: AulaProviderProps) => {
  const [aulas, setAulas] = useState<Aula[]>([]);

  return (
    <AulaContext.Provider value={{ aulas, setAulas }}>
      {children}
    </AulaContext.Provider>
  );
};

export function useAula() {
  const context = useContext(AulaContext);
  if (!context) {
    throw new Error('useAula deve ser usado dentro de um AulaProvider');
  }
  return context;
}

export default { useAula };
