import React from 'react';
import { createRoot } from 'react-dom/client';
import '../src/styles/Main/index.css';
import App from './app/App';

const container: HTMLElement =
  document.getElementById('root') || document.createElement('root');
const root = createRoot(container);

root.render(<App />);
