import { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { useModal } from '../../hooks/useModal';
import { Login as LoginType } from '../../interfaces/Login/LoginProps';
import { fetchLogins } from '../../services/Login/loginService';

const LoginManager: React.FC = () => {
  const loginModal = useModal();

  const [logins, setLogins] = useState<LoginType[]>([]);

  useEffect(() => {
    fetchLogins()
      .then((data) => {
        setLogins(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar logins:', error);
      });
  }, []);

  return (
    <>
      <button className="login-reg-botton" onClick={loginModal.showModal}>
        Logins
      </button>

      <Modal hideModal={loginModal.hideModal} isVisible={loginModal.isVisible}>
        <div className="alignTopModal">
          <h2>Registros de Logins</h2>
        </div>
        <div
          className="scroll"
          style={{
            height: '500px',
            overflowY: 'scroll',
            justifyContent: 'space-between',
          }}
        >
          {logins.map((login) => (
            <div
              key={login._id}
              style={{
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <p>
                <strong>Nome:</strong> {login.nome}
              </p>
              <p>
                <strong>Data:</strong> {login.data}
              </p>
              <p>
                <strong>Hora:</strong> {login.hora}
              </p>
              <p>
                <strong>IP:</strong> {login.ip}
              </p>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default LoginManager;
