import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { useModal } from '../../hooks/useModal';
import { Professor as ProfessorType } from '../../interfaces/Professor/ProfessorProps';
import {
  createProfessor,
  deleteProfessor,
  fetchProfessores,
  updateProfessor,
} from '../../services/Professor/professorService';
import { toast } from 'react-toastify';
import { useProfessor } from '../../context/ProfessorContext/ProfessorContext';

const ProfessorManager: React.FC = () => {
  const modalProfessor = useModal();
  const modalEdit = useModal();
  const modalCreate = useModal();
  const { professores, setProfessores } = useProfessor();
  const [editProfessor, setEditProfessor] = useState<ProfessorType | null>(
    null
  );
  const [newProfessor, setNewProfessor] = useState({ professor: '', tipo: '' });
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [professoresFiltrados, setProfessoresFiltrados] = useState<
    ProfessorType[]
  >([]);

  useEffect(() => {
    fetchProfessores()
      .then((data) => {
        setProfessores(data);
        setProfessoresFiltrados(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar professores:', error);
      });
  }, []);

  useEffect(() => {
    if (needsUpdate) {
      fetchProfessores().then((data) => {
        setProfessores(data);
        setProfessoresFiltrados(data);
        setNeedsUpdate(false);
      });
    }
  }, [needsUpdate]);

  useEffect(() => {
    if (!modalProfessor.isVisible && needsUpdate) {
      setNeedsUpdate(false);
      fetchProfessores().then((data) => {
        setProfessores(data);
        setProfessoresFiltrados(data);
      });
    }
  }, [modalProfessor.isVisible, needsUpdate]);

  useEffect(() => {
    if (modalProfessor.isVisible || needsUpdate) {
      fetchProfessores().then((data) => {
        setProfessores(data);
        setProfessoresFiltrados(data);
      });
    }
  }, [modalProfessor.isVisible, needsUpdate]);

  const handleEditClick = (professor: ProfessorType) => {
    setEditProfessor(professor);
    modalEdit.showModal();
  };

  const handleUpdateProfessor = async (professor: ProfessorType) => {
    try {
      await updateProfessor(professor._id, professor);
      modalEdit.hideModal();
      setEditProfessor(null);
      setNeedsUpdate(true);
      toast.success('Professor atualizado com sucesso');
    } catch (error) {
      console.error('Erro ao atualizar professor:', error);
      toast.error('Erro ao atualizar professor');
    }
  };

  const handleDeleteProfessor = async (id: string) => {
    try {
      await deleteProfessor(id);
      setNeedsUpdate(true);
      toast.success('Professor deletado com sucesso');
    } catch (error) {
      console.error('Erro ao deletar professor:', error);
      toast.error('Erro ao deletar professor');
    }
  };

  const handleCreateProfessor = async () => {
    try {
      await createProfessor(newProfessor);
      modalCreate.hideModal();
      setNewProfessor({ professor: '', tipo: '' });
      setNeedsUpdate(true);
      toast.success('Professor criado com sucesso');
    } catch (error) {
      console.error('Erro ao criar professor:', error);
      toast.error('Erro ao criar professor');
    }
  };

  function searchProfessores(termoDeBusca: string): ProfessorType[] {
    return professores.filter(
      (professor) =>
        professor.professor
          .toLowerCase()
          .includes(termoDeBusca.toLowerCase()) ||
        professor.tipo.toLowerCase().includes(termoDeBusca.toLowerCase())
    );
  }

  function handleSearch(value: string): void {
    setProfessoresFiltrados(searchProfessores(value));
  }

  return (
    <>
      <button className="criador" onClick={modalProfessor.showModal}>
        Professor
      </button>
      <Modal
        isVisible={modalProfessor.isVisible}
        hideModal={modalProfessor.hideModal}
      >
        <div className="alignTopModal">
          <h2>Professores</h2>
          <button className="criador" onClick={modalCreate.showModal}>
            Criar Professor
          </button>
        </div>
        <input
          style={{ width: '90%', marginTop: '10px', padding: '10px' }}
          type="text"
          placeholder="Digite aqui para buscar..."
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div
          className="scroll"
          style={{
            height: '500px',
            overflowY: 'scroll',
          }}
        >
          {Array.isArray(professoresFiltrados) &&
          professoresFiltrados.length > 0 ? (
            professoresFiltrados
              .slice()
              .sort((a, b) => a.professor.localeCompare(b.professor))
              .map((prof) => (
                <div
                  key={prof._id}
                  style={{
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                >
                  <p
                    style={{
                      fontSize: '22px',
                      width: '100px',
                      alignContent: 'center',
                    }}
                    className={'ele'}
                  >
                    {prof.professor}
                  </p>
                  <p
                    style={{
                      fontSize: '22px',
                      width: '100px',
                      alignContent: 'center',
                    }}
                    className={'ele'}
                  >
                    {prof.tipo === 'ele'
                      ? 'Elétrica'
                      : prof.tipo === 'adm'
                        ? 'Administração'
                        : prof.tipo === 'mec'
                          ? 'Mecânica'
                          : 'T.I'}
                  </p>
                  <button
                    className="feriado-botton"
                    onClick={() => handleEditClick(prof)}
                    style={{
                      cursor: 'pointer',
                      width: '50px',
                      height: '50px',
                    }}
                  >
                    Editar
                  </button>
                </div>
              ))
          ) : (
            <p>Nenhum professor encontrado.</p>
          )}
        </div>
      </Modal>

      <Modal
        isVisible={modalEdit.isVisible}
        hideModal={() => {
          modalEdit.hideModal();
          setNeedsUpdate(true);
        }}
      >
        <h2>Editar Professor</h2>
        {editProfessor && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateProfessor(editProfessor);
            }}
          >
            <p className="title-modal">Nome Professor:</p>
            <input
              type="text"
              value={editProfessor.professor}
              onChange={(e) =>
                setEditProfessor({
                  ...editProfessor,
                  professor: e.target.value,
                })
              }
              required
            />
            <p className="title-modal">Área de atuação</p>
            <select
              value={editProfessor.tipo}
              onChange={(e) =>
                setEditProfessor({
                  ...editProfessor,
                  tipo: e.target.value as 'ti' | 'ele' | 'adm' | 'mec' | 'otr',
                })
              }
              required
              name="atuacao"
            >
              <option value={'adm'}>Adm</option>
              <option value={'ele'}>Elétrica</option>
              <option value={'ti'}>T.I</option>
              <option value={'mec'}>Mecânica</option>
              <option value={'otr'}>Outro</option>
            </select>
            <br />
            <button type="submit" className="editar">
              Atualizar Professor
            </button>
            <button
              type="button"
              className="deletar"
              onClick={() => {
                handleDeleteProfessor(editProfessor._id);
                modalEdit.hideModal();
              }}
            >
              Deletar Professor
            </button>
          </form>
        )}
      </Modal>

      <Modal
        isVisible={modalCreate.isVisible}
        hideModal={modalCreate.hideModal}
      >
        <h2>Criar Novo Professor</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateProfessor();
          }}
        >
          <p className="title-modal">Nome Professor:</p>
          <input
            type="text"
            value={newProfessor.professor}
            placeholder="Digite o nome..."
            onChange={(e) =>
              setNewProfessor({ ...newProfessor, professor: e.target.value })
            }
            required
            name="professor"
          />
          <p className="title-modal">Área de atuação</p>
          <select
            value={newProfessor.tipo}
            onChange={(e) =>
              setNewProfessor({ ...newProfessor, tipo: e.target.value })
            }
            name="atuacao"
            required
          >
            <option></option>
            <option value={'ele'}>Elétrica</option>
            <option value={'ti'}>T.I</option>
            <option value={'mec'}>Mecânica</option>
            <option value={'adm'}>Adm</option>
            <option value={'otr'}>Outro</option>
          </select>
          <br />
          <button className="criador" type="submit">
            Criar
          </button>
        </form>
      </Modal>
    </>
  );
};

export default ProfessorManager;
