import { useEffect } from 'react';
import { useAmbiente } from '../../context/AmbienteContext/AmbienteContext';
import { useAula } from '../../context/AulaContext/AulaContext';
import { useAulaFiltroLista } from '../../context/AulaFiltroLista/AulaFiltroLista';
import { useProfessor } from '../../context/ProfessorContext/ProfessorContext';
import AulaManager from '../../manager/AulaManager/AulaManager';
import { fetchAulas } from '../../services/Aula/aulaService';
import SelectFilter from '../SelectFilter/SelectFilter';
import './style.css';
import { toast } from 'react-toastify';

function Menu() {
  const { ambientes } = useAmbiente();
  const nomesAmbientes = ambientes.map((ambiente) => ambiente.ambiente);

  const { professores } = useProfessor();
  const nomesProfessores = professores.map((professor) => professor.professor);

  const { setAulas } = useAula();

  const {
    setAmbienteFiltro,
    setProfessorFiltro,
    viewPeriod,
    setViewPeriod,
    setDataSelecionada,
    dataSelecionada,
  } = useAulaFiltroLista();

  function handleChangeNomeAmbiente(
    selectedOption: { value: string; label: string }[]
  ) {
    const selectedValues = selectedOption.map((option) => option.value);
    setAmbienteFiltro(selectedValues);
  }

  function handleChangeNomeProfessor(
    selectedOption: { value: string; label: string }[]
  ) {
    const selectedValues = selectedOption.map((option) => option.value);
    setProfessorFiltro(selectedValues);
  }

  function avancarTempo(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value === undefined || !e.target.value) {
      setDataSelecionada(new Date());
    } else {
      const novaData = new Date(e.target.value);
      setDataSelecionada(novaData);
    }
  }

  function avancarUmaSemana() {
    const novaData = new Date(dataSelecionada);
    novaData.setDate(dataSelecionada.getDate() + 7);
    setDataSelecionada(novaData);
  }

  function voltarUmaSemana() {
    const novaData = new Date(dataSelecionada);
    novaData.setDate(dataSelecionada.getDate() - 7);
    setDataSelecionada(novaData);
  }

  async function atualizarAulas() {
    fetchAulas().then((data) => {
      setAulas(data);
    });
  }

  const resetarData = () => {
    setDataSelecionada(new Date());
  };

  useEffect(() => {
    if (
      viewPeriod === 'month' ||
      viewPeriod === 'semester' ||
      viewPeriod === 'anual'
    ) {
      toast.info('Essa funcionalidade pode demorar um pouco!');
    }
  }, [viewPeriod]);

  return (
    <>
      <div className="componentes-menu">
        <button className="curso-botton" onClick={() => voltarUmaSemana()}>
          &lt; Voltar
        </button>

        <button className="criador" onClick={() => resetarData()}>
          Hoje
        </button>
        <button className="curso-botton" onClick={() => avancarUmaSemana()}>
          Avançar &gt;
        </button>
        <button className="atualizar-botton" onClick={() => atualizarAulas()}>
          Atualizar
        </button>

        <div className="selectProf">
          <label className="label-select">Salas</label>
          <SelectFilter
            onChange={handleChangeNomeAmbiente}
            meuArray={nomesAmbientes}
          />
        </div>
        <div className="selectProf">
          <label className="label-select">Professores</label>
          <SelectFilter
            onChange={handleChangeNomeProfessor}
            meuArray={nomesProfessores}
          />
        </div>

        <div className="semanas-radio">
          <div>
            <input
              type="radio"
              id="week"
              name="viewPeriod"
              value="week"
              checked={viewPeriod === 'week'}
              onChange={(e) => setViewPeriod(e.target.value)}
            />
            <label style={{ fontSize: '14px' }} htmlFor="week">
              Semana
            </label>
          </div>

          <div>
            <input
              type="radio"
              id="month"
              name="viewPeriod"
              value="month"
              checked={viewPeriod === 'month'}
              onChange={(e) => setViewPeriod(e.target.value)}
            />
            <label style={{ fontSize: '14px' }} htmlFor="month">
              Mês
            </label>
          </div>

          <div>
            <input
              type="radio"
              id="semester"
              name="viewPeriod"
              value="semester"
              checked={viewPeriod === 'semester'}
              onChange={(e) => setViewPeriod(e.target.value)}
            />
            <label style={{ fontSize: '14px' }} htmlFor="semester">
              Semestre
            </label>
          </div>

          <div>
            <input
              type="radio"
              id="anual"
              name="viewPeriod"
              value="anual"
              checked={viewPeriod === 'anual'}
              onChange={(e) => setViewPeriod(e.target.value)}
            />
            <label style={{ fontSize: '14px' }} htmlFor="anual">
              Ano
            </label>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginLeft: '20px' }}>
            <label style={{ fontSize: '16px' }}>Escolha o tempo</label>
            <br />
            <input
              style={{
                fontSize: '18px',
                marginLeft: '10px',
                border: '1px solid black',
                width: '100px',
              }}
              type="date"
              onChange={(e) => avancarTempo(e)}
            />
          </div>

          <div>
            <AulaManager />
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;
