import './style.css';

function Info() {
  return (
    <>
      <div className="container-info-cursos">
        <div className="Container">
          <div className="apoio">
            <div className="CT-info">
              <div className="Block"></div>
              <h4>CT</h4>
            </div>
            <div className="CAI-info">
              <h4>|</h4>
              <div className="Block"></div>
              <h4>CAI</h4>
            </div>

            <div className="FIC-info">
              <h4>|</h4>
              <div className="Block"></div>
              <h4>FIC</h4>
            </div>
            <div className="FIC-EXT-info">
              <h4>|</h4>
              <div className="Block"></div>
              <h4>FIC-EXT</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Info;
