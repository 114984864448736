import axiosInstance from '../../utils/axiosConfig';
import { Ambiente as AmbienteType } from '../../interfaces/Ambiente/AmbienteProps';

export const fetchAmbientes = async (): Promise<AmbienteType[]> => {
  const response = await axiosInstance.get(`/ambiente/`);
  return response.data.ambientes;
};

export const createAmbiente = async (ambiente: {
  ambiente: string;
  capacidade: string;
  posicao: string;
}) => {
  await axiosInstance.post(`/ambiente/`, ambiente);
};

export const updateAmbiente = async (id: string, ambiente: AmbienteType) => {
  await axiosInstance.put(`/ambiente/${id}`, ambiente);
};

export const deleteAmbiente = async (id: string) => {
  await axiosInstance.delete(`/ambiente/${id}`);
};
