import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Ambiente } from '../../interfaces/Ambiente/AmbienteProps';

interface AmbienteContextType {
  ambientes: Ambiente[];
  setAmbientes: React.Dispatch<React.SetStateAction<Ambiente[]>>;
}

const AmbienteContext = createContext<AmbienteContextType | undefined>(
  undefined
);

interface AmbienteProviderProps {
  children: ReactNode;
}

export const AmbienteProvider = ({ children }: AmbienteProviderProps) => {
  const [ambientes, setAmbientes] = useState<Ambiente[]>([]);

  return (
    <AmbienteContext.Provider value={{ ambientes, setAmbientes }}>
      {children}
    </AmbienteContext.Provider>
  );
};

export function useAmbiente() {
  const context = useContext(AmbienteContext);
  if (!context) {
    throw new Error('useAmbiente deve ser usado dentro de um AmbienteProvider');
  }
  return context;
}

export default { useAmbiente };
