import axiosInstance from '../../utils/axiosConfig';

const HealthService = {
  healthCheck: async () => {
    try {
      await axiosInstance.get(`/healthcheck`);
      return true;
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      return false;
    }
  },
};

export default HealthService;
