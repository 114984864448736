import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { useModal } from '../../hooks/useModal';
import { Filtro as FiltroType } from '../../interfaces/Filtro/FiltroProps';
import {
  createFiltro,
  deleteFiltro,
  fetchFiltro,
  updateFiltro,
} from '../../services/Filtro/filtroService';
import { toast } from 'react-toastify';
import { useFiltro } from '../../context/FiltroContext/FiltroContext';

const FiltroManager: React.FC = () => {
  const modalFiltro = useModal();
  const modalEdit = useModal();
  const modalCreate = useModal();
  const { filtros, setFiltros } = useFiltro();
  const [editFiltro, setEditFiltro] = useState<FiltroType | null>(null);
  const [newFiltro, setNewFiltro] = useState({ nome: '', destino: '' });
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [filtroFiltrados, setFiltroFiltrados] = useState<FiltroType[]>([]);

  const destinos = [
    { label: 'CAI', value: 'CAI' },
    { label: 'CT', value: 'CT' },
  ];

  useEffect(() => {
    fetchFiltro()
      .then((data) => {
        setFiltros(data);
        setFiltroFiltrados(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar filtros:', error);
      });
  }, []);

  useEffect(() => {
    if (needsUpdate) {
      fetchFiltro().then((data) => {
        setFiltros(data);
        setFiltroFiltrados(data);
        setNeedsUpdate(false);
      });
    }
  }, [needsUpdate]);

  const handleEditClick = (filtro: FiltroType) => {
    setEditFiltro(filtro);
    modalEdit.showModal();
  };

  useEffect(() => {
    if (!modalFiltro.isVisible && needsUpdate) {
      setNeedsUpdate(false);
      fetchFiltro().then((data) => {
        setFiltros(data);
        setFiltroFiltrados(data);
      });
    }
  }, [modalFiltro.isVisible, needsUpdate]);

  const handleUpdateFiltro = async (filtro: FiltroType) => {
    try {
      await updateFiltro(filtro._id, filtro);
      modalEdit.hideModal();
      setEditFiltro(null);
      setNeedsUpdate(true);
      toast.success('Filtro atualizado com sucesso');
    } catch (error) {
      console.error('Erro ao atualizar filtro:', error);
      toast.error('Erro ao atualizar filtro');
    }
  };

  const handleDeleteFiltro = async (id: string) => {
    try {
      await deleteFiltro(id);
      setNeedsUpdate(true);
      toast.success('Filtro deletado com sucesso');
    } catch (error) {
      console.error('Erro ao deletar filtro:', error);
      toast.error('Erro ao deletar filtro');
    }
  };

  const handleCreateFiltro = async () => {
    try {
      console.log(newFiltro);
      await createFiltro(newFiltro);
      modalCreate.hideModal();
      setNewFiltro({ nome: '', destino: '' });
      setNeedsUpdate(true);
      toast.success('Filtro criado com sucesso');
    } catch (error) {
      console.error('Erro ao criar filtro:', error);
      toast.error('Erro ao criar filtro');
    }
  };

  const handleNew = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newDestino = e.target.value;
    if (newFiltro) {
      setNewFiltro({
        ...newFiltro,
        destino: newDestino,
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const editDestino = e.target.value;
    if (editFiltro) {
      setEditFiltro({
        ...editFiltro,
        destino: editDestino,
      });
    }
  };

  function searchFiltro(termoDeBusca: string): FiltroType[] {
    return filtros.filter(
      (filtro) =>
        filtro.nome.toLowerCase().includes(termoDeBusca.toLowerCase()) ||
        filtro.destino.toLowerCase().includes(termoDeBusca.toLowerCase())
    );
  }

  function handleSearch(value: string): void {
    setFiltroFiltrados(searchFiltro(value));
  }

  return (
    <>
      <button className="curso-botton" onClick={modalFiltro.showModal}>
        Filtro
      </button>
      <Modal
        isVisible={modalFiltro.isVisible}
        hideModal={modalFiltro.hideModal}
      >
        <div className="alignTopModal">
          <h2>Filtros</h2>
          <button className="criador" onClick={modalCreate.showModal}>
            Criar Filtro
          </button>
        </div>
        <input
          style={{ width: '90%', marginTop: '10px', padding: '10px' }}
          type="text"
          placeholder="Digite aqui para buscar..."
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div
          className="scroll"
          style={{
            height: '500px',
            overflowY: 'scroll',
            justifyContent: 'space-between',
          }}
        >
          {filtroFiltrados
            .slice()
            .sort((a, b) => a.nome.localeCompare(b.nome))
            .map((filtro) => (
              <div
                key={filtro._id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <span style={{ fontSize: '22px', marginLeft: '50px' }}>
                  {filtro.nome} - {filtro.destino}
                </span>
                <div>
                  <button
                    className="feriado-botton"
                    style={{
                      cursor: 'pointer',
                      width: '50px',
                      height: '50px',
                    }}
                    onClick={() => handleEditClick(filtro)}
                  >
                    Editar
                  </button>
                </div>
              </div>
            ))}
        </div>
      </Modal>

      <Modal
        isVisible={modalEdit.isVisible}
        hideModal={() => {
          modalEdit.hideModal();
          setNeedsUpdate(true);
        }}
      >
        <h2>Editar Filtro</h2>
        {editFiltro && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateFiltro(editFiltro);
            }}
          >
            <p className="title-modal">Nome Filtro</p>
            <input
              type="text"
              placeholder="Digite o nome..."
              value={editFiltro.nome}
              onChange={(e) =>
                setEditFiltro({ ...editFiltro, nome: e.target.value })
              }
              required
            />
            <p className="title-modal">Selecione o Destino</p>
            <select
              value={editFiltro.destino}
              onChange={(e) => handleChange(e)}
              required
            >
              {destinos.map((d) => (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              ))}
            </select>
            <br />
            <button className="editar" type="submit">
              Atualizar Filtro
            </button>
            <button
              type="button"
              className="deletar"
              onClick={() => {
                handleDeleteFiltro(editFiltro._id);
                modalEdit.hideModal();
              }}
            >
              Deletar Filtro
            </button>
          </form>
        )}
      </Modal>

      <Modal
        isVisible={modalCreate.isVisible}
        hideModal={modalCreate.hideModal}
      >
        <h2>Criar Novo Filtro</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateFiltro();
          }}
        >
          <p className="title-modal">Nome Filtro</p>
          <input
            type="text"
            placeholder="Digite o nome..."
            value={newFiltro.nome}
            onChange={(e) =>
              setNewFiltro({ ...newFiltro, nome: e.target.value })
            }
            required
          />
          <p className="title-modal">Selecione o Destino:</p>
          <select
            required
            value={newFiltro.destino}
            onChange={(e) => handleNew(e)}
          >
            <option>Selecione uma opcao</option>
            {destinos.map((d) => (
              <option key={d.value} value={d.value}>
                {d.label}
              </option>
            ))}
          </select>
          <br />
          <button className="criador" type="submit">
            Criar
          </button>
        </form>
      </Modal>
    </>
  );
};

export default FiltroManager;
