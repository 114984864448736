import { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { useUsuario } from '../../context/UsuarioContext/UsuarioContext';
import { useModal } from '../../hooks/useModal';
import { Usuario as UsuarioType } from '../../interfaces/Usuario/UsuarioProps';
import {
  createUsuario,
  deleteUsuario,
  fetchUsuarios,
  updateUsuario,
} from '../../services/Usuario/usuarioService';
import { toast } from 'react-toastify';

const UserManager: React.FC = () => {
  const modalUser = useModal();
  const modalEdit = useModal();
  const modalCreate = useModal();

  const { usuarios, setUsuarios } = useUsuario();
  const [editUsuario, setEditUsuario] = useState<UsuarioType | null>(null);
  const [newUsuario, setNewUsuario] = useState({
    username: '',
    password: '',
    level: '',
  });
  const [newPassword, setNewPassword] = useState<string>(''); // Variável para a nova senha
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [usuariosFiltrados, setUsuariosFiltrados] = useState<UsuarioType[]>([]);

  useEffect(() => {
    fetchUsuarios()
      .then((data) => {
        setUsuarios(data);
        setUsuariosFiltrados(data);
      })
      .catch((error) => {
        console.log('Erro ao buscar usuarios:', error);
      });
  }, []);

  useEffect(() => {
    if (needsUpdate) {
      fetchUsuarios().then((data) => {
        setUsuarios(data);
        setUsuariosFiltrados(data);
        setNeedsUpdate(false);
      });
    }
  }, [needsUpdate]);

  useEffect(() => {
    if (!modalUser.isVisible && needsUpdate) {
      setNeedsUpdate(false);
      fetchUsuarios().then((data) => {
        setUsuarios(data);
        setUsuariosFiltrados(data);
      });
    }
  }, [modalUser.isVisible, needsUpdate]);

  useEffect(() => {
    if (modalUser.isVisible || needsUpdate) {
      fetchUsuarios().then((data) => {
        setUsuarios(data);
        setUsuariosFiltrados(data);
      });
    }
  }, [modalUser.isVisible, needsUpdate]);

  const handleEditClick = (usuario: UsuarioType) => {
    setEditUsuario(usuario);
    modalEdit.showModal();
  };

  const handleUpdateUsuario = async (usuario: UsuarioType) => {
    try {
      const updatedUsuario = { ...usuario };
      if (newPassword) {
        updatedUsuario.password = newPassword;
      }

      await updateUsuario(updatedUsuario._id, updatedUsuario);
      modalEdit.hideModal();
      setEditUsuario(null);
      setNewPassword(''); // Resetar a nova senha
      setNeedsUpdate(true);
      toast.success('Usuario atualizado com sucesso');
    } catch (error) {
      console.error('Erro ao atualizar usuario:', error);
      toast.error('Erro ao atualizar usuario');
    }
  };

  const handleDeleteUsuario = async (id: string) => {
    try {
      await deleteUsuario(id);
      setNeedsUpdate(true);
      toast.success('Usuario deletado com sucesso');
    } catch (error) {
      console.error('Erro ao deletar usuario:', error);
      toast.error('Erro ao deletar usuario');
    }
  };

  const handleCreateUsuario = async () => {
    try {
      await createUsuario(newUsuario);
      modalCreate.hideModal();
      setNewUsuario({ username: '', password: '', level: '' });
      setNeedsUpdate(true);
      toast.success('Usuario criado com sucesso');
    } catch (error) {
      console.error('Erro ao criar usuario:', error);
      toast.error('Erro ao criar usuario');
    }
  };

  function searchUsuarios(termoDeBusca: string): UsuarioType[] {
    return usuarios.filter((usuario) =>
      usuario.username.toLowerCase().includes(termoDeBusca.toLowerCase())
    );
  }

  function handleSearch(value: string): void {
    setUsuariosFiltrados(searchUsuarios(value));
  }

  const destinos = [
    { label: 'ADMIN', value: 'ADMIN' },
    { label: 'USUARIO', value: 'USUARIO' },
  ];

  const handleNew = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newDestino = e.target.value;
    if (newUsuario) {
      setNewUsuario({
        ...newUsuario,
        level: newDestino,
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const editDestino = e.target.value;
    if (editUsuario) {
      setEditUsuario({
        ...editUsuario,
        level: editDestino,
      });
    }
  };

  return (
    <>
      <button className="usuario-botton" onClick={modalUser.showModal}>
        Usuário
      </button>
      <Modal hideModal={modalUser.hideModal} isVisible={modalUser.isVisible}>
        <div className="alignTopModal">
          <h2>Usuarios</h2>
          <button className="criador" onClick={modalCreate.showModal}>
            Criar Usuario
          </button>
        </div>
        <input
          style={{ width: '90%', marginTop: '10px', padding: '10px' }}
          type="text"
          placeholder="Digite aqui para buscar..."
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div
          className="scroll"
          style={{
            height: '500px',
            overflowY: 'scroll',
          }}
        >
          {Array.isArray(usuariosFiltrados) && usuariosFiltrados.length > 0 ? (
            usuariosFiltrados
              .slice()
              .sort((a, b) => a.username.localeCompare(b.username))
              .map((user) => (
                <div
                  key={user._id}
                  style={{
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                >
                  <p
                    style={{
                      fontSize: '22px',
                      width: '100px',
                      alignContent: 'center',
                    }}
                    className={'ele'}
                  >
                    {user.username}
                  </p>
                  <button
                    className="feriado-botton"
                    onClick={() => handleEditClick(user)}
                    style={{
                      cursor: 'pointer',
                      width: '50px',
                      height: '50px',
                    }}
                  >
                    Editar
                  </button>
                </div>
              ))
          ) : (
            <p>Nenhum usuário encontrado.</p>
          )}
        </div>
      </Modal>

      <Modal
        isVisible={modalEdit.isVisible}
        hideModal={() => {
          modalEdit.hideModal();
          setNeedsUpdate(true);
        }}
      >
        <h2>Editar Usuario</h2>
        {editUsuario && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateUsuario(editUsuario);
            }}
          >
            <p className="title-modal">Nome Usuario:</p>
            <input
              type="text"
              value={editUsuario.username}
              onChange={(e) =>
                setEditUsuario({
                  ...editUsuario,
                  username: e.target.value,
                })
              }
              required
            />
            <p className="title-modal">Password:</p>
            <input
              required
              type="password"
              placeholder="Digite uma nova senha"
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <p className="title-modal">Selecione o Nivel</p>
            <select
              value={editUsuario.level}
              onChange={(e) => handleChange(e)}
              required
            >
              {destinos.map((d) => (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              ))}
            </select>
            <br />
            <button type="submit" className="editar">
              Atualizar Usuario
            </button>
            <button
              type="button"
              className="deletar"
              onClick={() => {
                handleDeleteUsuario(editUsuario._id);
                modalEdit.hideModal();
              }}
            >
              Deletar Usuario
            </button>
          </form>
        )}
      </Modal>

      <Modal
        isVisible={modalCreate.isVisible}
        hideModal={modalCreate.hideModal}
      >
        <h2>Criar Usuario</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateUsuario();
          }}
        >
          <p className="title-modal">Nome Usuario:</p>
          <input
            type="text"
            value={newUsuario.username}
            onChange={(e) =>
              setNewUsuario({
                ...newUsuario,
                username: e.target.value,
              })
            }
            required
          />
          <p className="title-modal">Password:</p>
          <input
            type="password"
            value={newUsuario.password}
            onChange={(e) =>
              setNewUsuario({
                ...newUsuario,
                password: e.target.value,
              })
            }
            required
          />
          <p className="title-modal">Selecione o Nivel</p>
          <select
            value={newUsuario.level}
            onChange={(e) => handleNew(e)}
            required
          >
            <option></option>
            {destinos.map((d) => (
              <option key={d.value} value={d.value}>
                {d.label}
              </option>
            ))}
          </select>
          <br />
          <button className="criador" type="submit">
            Criar
          </button>
        </form>
      </Modal>
    </>
  );
};

export default UserManager;
