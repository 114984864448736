import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Feriado } from '../../interfaces/Feriado/FeriadoProps';

interface FeriadoContextType {
  feriados: Feriado[];
  setFeriados: React.Dispatch<React.SetStateAction<Feriado[]>>;
}

const FeriadoContext = createContext<FeriadoContextType | undefined>(undefined);

interface FeriadoProviderProps {
  children: ReactNode;
}

export const FeriadoProvider = ({ children }: FeriadoProviderProps) => {
  const [feriados, setFeriados] = useState<Feriado[]>([]);

  return (
    <FeriadoContext.Provider value={{ feriados, setFeriados }}>
      {children}
    </FeriadoContext.Provider>
  );
};

export function useFeriado() {
  const context = useContext(FeriadoContext);
  if (!context) {
    throw new Error('useFeriado deve ser usado dentro de um FeriadoProvider');
  }
  return context;
}

export default { useFeriado };
