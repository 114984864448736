import React, { useState } from 'react';
import Select from 'react-select';

interface SelectFilterProps {
  meuArray: string[];
  // eslint-disable-next-line
  onChange: (selectedOption: any) => void;
}

const SelectFilter: React.FC<SelectFilterProps> = ({ meuArray, onChange }) => {
  // eslint-disable-next-line
  const [selectedOption, setSelectedOption] = useState<any>(null); // Ajuste o tipo conforme necessário
  // eslint-disable-next-line
  const handleChange = (selectedOption: any) => {
    onChange(selectedOption);
    setSelectedOption(selectedOption);
  };

  const options = meuArray.map((item) => ({
    value: item,
    label: item,
  }));

  const customStyles = {
    // eslint-disable-next-line
    valueContainer: (provided: any) => ({
      ...provided,
      flexWrap: 'wrap',
      width: '200px',
      maxHeight: '200px',
    }),
    // eslint-disable-next-line
    multiValue: (provided: any) => ({
      ...provided,
      display: 'block',
      marginRight: '10px',
      marginBottom: '10px',
      marginTop: '10px',
      marginLeft: '10px',
    }),
  };

  return (
    <div>
      <label htmlFor="idSelectReact">Clique para selecionar:</label>
      <Select
        id="idSelectReact"
        value={selectedOption}
        onChange={handleChange}
        options={options}
        isMulti
        styles={customStyles}
        className="basic-multi-select"
        classNamePrefix="basic-multi-select"
      />
    </div>
  );
};

export default SelectFilter;
