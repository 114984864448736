import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Filtro } from '../../interfaces/Filtro/FiltroProps';

interface FiltroContextType {
  filtros: Filtro[];
  setFiltros: React.Dispatch<React.SetStateAction<Filtro[]>>;
}

const FiltroContext = createContext<FiltroContextType | undefined>(undefined);

interface FiltroProviderProps {
  children: ReactNode;
}

export const FiltroProvider = ({ children }: FiltroProviderProps) => {
  const [filtros, setFiltros] = useState<Filtro[]>([]);

  return (
    <FiltroContext.Provider value={{ filtros, setFiltros }}>
      {children}
    </FiltroContext.Provider>
  );
};

export function useFiltro() {
  const context = useContext(FiltroContext);
  if (!context) {
    throw new Error('useFiltro deve ser usado dentro de um FiltroProvider');
  }
  return context;
}

export default { useFiltro };
