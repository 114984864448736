import React, { useEffect, useState } from 'react';
import { useModal } from '../../hooks/useModal';
import Modal from '../../components/Modal/Modal';
import { Feriado as FeriadoType } from '../../interfaces/Feriado/FeriadoProps';
import {
  fetchFeriados,
  createFeriado,
  updateFeriado,
  deleteFeriado,
} from '../../services/Feriado/feriadoService';
import { useFeriado } from '../../context/FeriadoContext/FeriadoContext';
import { toast } from 'react-toastify';

const FeriadoManager: React.FC = () => {
  const modalFeriado = useModal();
  const modalEdit = useModal();
  const modalCreate = useModal();

  const { feriados, setFeriados } = useFeriado();

  const [editFeriado, setEditFeriado] = useState<FeriadoType | null>(null);
  const [newFeriado, setNewFeriado] = useState({ nome: '', data: '' });
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [feriadosFiltrados, setFeriadosFiltrados] = useState<FeriadoType[]>([]);

  useEffect(() => {
    fetchFeriados()
      .then((data) => {
        setFeriados(data);
        setFeriadosFiltrados(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar feriados:', error);
      });
  }, []);

  useEffect(() => {
    if (needsUpdate) {
      fetchFeriados().then((data) => {
        setFeriados(data);
        setFeriadosFiltrados(data);
        setNeedsUpdate(false);
      });
    }
  }, [needsUpdate]);

  const handleEditClick = (feriado: FeriadoType) => {
    setEditFeriado(feriado);
    modalEdit.showModal();
  };

  useEffect(() => {
    if (!modalFeriado.isVisible && needsUpdate) {
      setNeedsUpdate(false);
      fetchFeriados().then((data) => {
        setFeriados(data);
        setFeriadosFiltrados(data);
      });
    }
  }, [modalFeriado.isVisible, needsUpdate]);

  const handleUpdateFeriado = async (feriado: FeriadoType) => {
    try {
      await updateFeriado(feriado._id, feriado);
      modalEdit.hideModal();
      setEditFeriado(null);
      setNeedsUpdate(true);
      toast.success('Feriado atualizado com sucesso');
    } catch (error) {
      console.error('Erro ao atualizar feriado:', error);
      toast.error('Erro ao atualizar feriado');
    }
  };

  const handleDeleteFeriado = async (id: string) => {
    try {
      await deleteFeriado(id);
      setNeedsUpdate(true);
      toast.success('Feriado deletado com sucesso');
    } catch (error) {
      console.error('Erro ao deletar feriado:', error);
      toast.error('Erro ao deletar feriado');
    }
  };

  const handleCreateFeriado = async () => {
    try {
      await createFeriado(newFeriado);
      modalCreate.hideModal();
      setNewFeriado({ nome: '', data: '' });
      setNeedsUpdate(true);
      toast.success('Feriado criado com sucesso');
    } catch (error) {
      console.error('Erro ao criar feriado:', error);
      toast.error('Erro ao criar feriado');
    }
  };

  function searchFeriados(termoDeBusca: string): FeriadoType[] {
    return feriados.filter(
      (feriado) =>
        feriado.nome.toLowerCase().includes(termoDeBusca.toLowerCase()) ||
        feriado.data.toLowerCase().includes(termoDeBusca.toLowerCase())
    );
  }

  function handleSearch(value: string): void {
    setFeriadosFiltrados(searchFeriados(value));
  }

  return (
    <>
      <button
        className="feriado-botton"
        id="feriado-ajuste"
        onClick={modalFeriado.showModal}
      >
        Feriado
      </button>
      <Modal
        isVisible={modalFeriado.isVisible}
        hideModal={modalFeriado.hideModal}
      >
        <div className="alignTopModal">
          <h2>Feriados</h2>
          <button className="criador" onClick={modalCreate.showModal}>
            Criar Feriado
          </button>
        </div>
        <input
          style={{ width: '90%', marginTop: '10px', padding: '10px' }}
          type="text"
          placeholder="Digite aqui para buscar..."
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div
          className="scroll"
          style={{
            height: '500px',
            overflowY: 'scroll',
            justifyContent: 'space-between',
          }}
        >
          {feriadosFiltrados
            .slice()
            .sort((a, b) => a.nome.localeCompare(b.nome))
            .map((feriado) => (
              <div
                key={feriado._id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    fontSize: '22px',
                    marginLeft: 'auto',
                    textAlign: 'center',
                  }}
                >
                  {feriado.nome}
                </span>
                <span
                  style={{
                    fontSize: '22px',
                    marginLeft: 'auto',
                    width: '250px',
                  }}
                >
                  {feriado.data}
                </span>
                <div>
                  <button
                    className="feriado-botton"
                    style={{
                      cursor: 'pointer',
                      width: '50px',
                      height: '50px',
                    }}
                    onClick={() => handleEditClick(feriado)}
                  >
                    Editar
                  </button>
                  {/*               <button onClick={() => handleDeleteFeriado(feriado._id)}>
                Deletar
              </button> */}
                </div>
              </div>
            ))}
        </div>
      </Modal>

      <Modal
        isVisible={modalEdit.isVisible}
        hideModal={() => {
          modalEdit.hideModal();
          setNeedsUpdate(true);
        }}
      >
        <h2>Editar Feriado</h2>
        {editFeriado && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateFeriado(editFeriado);
            }}
          >
            <p className="title-modal">Nome Feriado</p>
            <input
              type="text"
              placeholder="Digite o nome..."
              value={editFeriado.nome}
              onChange={(e) =>
                setEditFeriado({
                  ...editFeriado,
                  nome: e.target.value,
                })
              }
              required
            />
            <p className="title-modal">Data Feriado</p>
            <input
              type="date"
              value={editFeriado.data}
              onChange={(e) =>
                setEditFeriado({
                  ...editFeriado,
                  data: e.target.value,
                })
              }
              required
            />
            <br />
            <button className="editar" type="submit">
              Atualizar Feriado
            </button>
            <button
              type="button"
              className="deletar"
              onClick={() => {
                handleDeleteFeriado(editFeriado._id);
                modalEdit.hideModal();
              }}
            >
              Deletar Feriado
            </button>
          </form>
        )}
      </Modal>

      <Modal
        isVisible={modalCreate.isVisible}
        hideModal={modalCreate.hideModal}
      >
        <h2>Criar Novo Feriado</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateFeriado();
          }}
        >
          <p className="title-modal">Nome Feriado</p>
          <input
            type="text"
            placeholder="Digite o nome..."
            value={newFeriado.nome}
            onChange={(e) =>
              setNewFeriado({ ...newFeriado, nome: e.target.value })
            }
            required
          />
          <p className="title-modal">Data Feriado:</p>
          <input
            type="date"
            value={newFeriado.data}
            onChange={(e) =>
              setNewFeriado({ ...newFeriado, data: e.target.value })
            }
          />
          <br />
          <button className="criador" type="submit">
            Criar
          </button>
        </form>
      </Modal>
    </>
  );
};

export default FeriadoManager;
