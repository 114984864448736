import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { useModal } from '../../hooks/useModal';
import { Curso as CursoType } from '../../interfaces/Curso/CursoProps';
import {
  createCurso,
  deleteCurso,
  fetchCurso,
  updateCurso,
} from '../../services/Curso/cursoService';
import { Filtro as FiltroType } from '../../interfaces/Filtro/FiltroProps';
import { fetchFiltro } from '../../services/Filtro/filtroService';
import { toast } from 'react-toastify';
import { useCurso } from '../../context/CursoContext/CursoContext';

const TecnicoManager: React.FC = () => {
  const modalTecnico = useModal();
  const modalEdit = useModal();
  const modalCreate = useModal();
  const { tecnicos, setTecnicos } = useCurso();

  const [editTecnico, setEditTecnico] = useState<CursoType | null>(null);
  const [newTecnico, setNewTecnico] = useState({
    curso: '',
    tempoTotal: '',
    tipo: 'CT',
    filtro: '',
  });
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [filtros, setFiltros] = useState<FiltroType[]>([]);
  const [tecnicosFiltrados, setTecnicosFiltrados] = useState<CursoType[]>([]);

  useEffect(() => {
    fetchCurso()
      .then((data) => {
        setTecnicos(data);
        setTecnicosFiltrados(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar tecnicos:', error);
      });
  }, []);

  useEffect(() => {
    if (
      modalTecnico.isVisible ||
      modalEdit.isVisible ||
      modalCreate.isVisible
    ) {
      fetchFiltro().then((data) => {
        setFiltros(data);
      });
    }
  }, [modalTecnico.isVisible, modalEdit.isVisible, modalCreate.isVisible]);

  useEffect(() => {
    if (!modalCreate.isVisible) {
      setNewTecnico({
        curso: '',
        tempoTotal: '',
        tipo: 'CT',
        filtro: '',
      });
    }
  }, [modalCreate.isVisible]);

  useEffect(() => {
    if (needsUpdate) {
      fetchCurso().then((data) => {
        setTecnicos(data);
        setTecnicosFiltrados(data);
        setNeedsUpdate(false);
      });
    }
  }, [needsUpdate]);

  const handleEditClick = (tecnico: CursoType) => {
    setEditTecnico(tecnico);
    modalEdit.showModal();
  };

  useEffect(() => {
    if (!modalTecnico.isVisible && needsUpdate) {
      setNeedsUpdate(false);
      fetchCurso().then((data) => {
        setTecnicos(data);
        setTecnicosFiltrados(data);
      });
    }
  }, [modalTecnico.isVisible, needsUpdate]);

  const handleUpdateTecnico = async (tecnico: CursoType) => {
    try {
      await updateCurso(tecnico._id, tecnico);
      modalEdit.hideModal();
      setEditTecnico(null);
      setNeedsUpdate(true);
      toast.success('Tecnico atualizado com sucesso');
    } catch (error) {
      console.error('Erro ao atualizar tecnico:', error);
      toast.error('Erro ao atualizar tecnico');
    }
  };

  const handleDeleteTecnico = async (id: string) => {
    try {
      await deleteCurso(id);
      setNeedsUpdate(true);
      toast.success('Tecnico deletado com sucesso');
    } catch (error) {
      console.error('Erro ao deletar tecnico:', error);
      toast.error('Erro ao deletar tecnico');
    }
  };

  const handleCreateTecnico = async () => {
    try {
      await createCurso(newTecnico);
      modalCreate.hideModal();
      setNewTecnico({
        curso: '',
        tempoTotal: '',
        tipo: 'CT',
        filtro: '',
      });
      setNeedsUpdate(true);
      toast.success('Tecnico criado com sucesso');
    } catch (error) {
      console.error('Erro ao criar tecnico:', error);
      toast.error('Erro ao criar tecnico');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFiltroId = e.target.value;
    if (editTecnico) {
      setEditTecnico({
        ...editTecnico,
        filtro: newFiltroId,
      });
    }
  };

  const handleNew = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFiltroId = e.target.value;
    if (newTecnico) {
      setNewTecnico({
        ...newTecnico,
        filtro: newFiltroId,
      });
    }
  };

  function searchTecnicos(termoDeBusca: string): CursoType[] {
    return tecnicos.filter(
      (tecnico) =>
        tecnico.curso?.toLowerCase().includes(termoDeBusca.toLowerCase()) ||
        tecnico.tempoTotal
          ?.toLowerCase()
          .includes(termoDeBusca.toLowerCase()) ||
        tecnico.tipo?.toLowerCase().includes(termoDeBusca.toLowerCase())
    );
  }

  function handleSearch(value: string): void {
    setTecnicosFiltrados(searchTecnicos(value));
  }

  const decodeCourseName = (name: any) => {
    try {
      return decodeURIComponent(escape(name));
    } catch (e) {
      return name;
    }
  };

  return (
    <>
      <button className="curso-tecnico" onClick={modalTecnico.showModal}>
        CT
      </button>
      <Modal
        isVisible={modalTecnico.isVisible}
        hideModal={modalTecnico.hideModal}
      >
        <div className="alignTopModal">
          <h2>Tecnicos</h2>
          <button className="criador" onClick={modalCreate.showModal}>
            Criar CT
          </button>
        </div>
        <input
          style={{ width: '90%', marginTop: '10px', padding: '10px' }}
          type="text"
          placeholder="Digite aqui para buscar..."
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div
          className="scroll"
          style={{ height: '500px', overflowY: 'scroll' }}
        >
          {filtros.length > 0
            ? filtros?.map((filtro) =>
                filtro.destino === 'CT' ? (
                  <>
                    <h2>{filtro.nome}</h2>
                    {tecnicosFiltrados
                      .slice()
                      .sort((a, b) => a.curso.localeCompare(b.curso))
                      .map((tecnico) =>
                        tecnico.tipo === 'CT' &&
                        tecnico.filtro === filtro.nome ? (
                          <>
                            <div
                              key={tecnico._id}
                              style={{
                                marginBottom: '20px',
                                display: 'flex',
                                justifyContent: 'space-around',
                              }}
                            >
                              <p
                                style={{ fontSize: '22px', marginLeft: 'auto' }}
                              >
                                {decodeCourseName(tecnico?.curso)} -{' '}
                                {tecnico?.tempoTotal}hrs
                              </p>
                              <button
                                className="feriado-botton"
                                style={{
                                  cursor: 'pointer',
                                  width: '50px',
                                  height: '50px',
                                  marginLeft: 'auto',
                                }}
                                onClick={() => handleEditClick(tecnico)}
                              >
                                Editar
                              </button>
                            </div>
                          </>
                        ) : null
                      )}
                    <hr />
                  </>
                ) : (
                  <></>
                )
              )
            : null}
        </div>
      </Modal>

      <Modal
        isVisible={modalEdit.isVisible}
        hideModal={() => {
          modalEdit.hideModal();
          setNeedsUpdate(true);
        }}
      >
        <h2>Editar Tecnico</h2>
        {editTecnico && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateTecnico(editTecnico);
            }}
          >
            <p className="title-modal">Nome</p>
            <input
              type="text"
              placeholder="Digite o nome..."
              value={editTecnico.curso}
              onChange={(e) =>
                setEditTecnico({
                  ...editTecnico,
                  curso: e.target.value,
                })
              }
              required
            />
            <p className="title-modal">Tempo Total</p>
            <input
              placeholder="Digite o tempo..."
              type="number"
              value={editTecnico.tempoTotal}
              onChange={(e) =>
                setEditTecnico({
                  ...editTecnico,
                  tempoTotal: e.target.value,
                })
              }
              required
            />
            <p className="title-modal">Tipo do Curso</p>
            <select
              value={editTecnico.filtro}
              onChange={(e) => handleChange(e)}
              required
            >
              <option></option>
              {filtros.map((filtro) =>
                filtro.destino === 'CT' ? (
                  <option key={filtro._id} value={filtro.nome}>
                    {filtro.nome} - {filtro.destino}
                  </option>
                ) : null
              )}
            </select>
            <br />
            <button className="editar" type="submit">
              Atualizar CT
            </button>
            <button
              type="button"
              className="deletar"
              onClick={() => {
                handleDeleteTecnico(editTecnico._id);
                modalEdit.hideModal();
              }}
            >
              Deletar CT
            </button>
          </form>
        )}
      </Modal>

      <Modal
        isVisible={modalCreate.isVisible}
        hideModal={modalCreate.hideModal}
      >
        <h2>Criar Novo Tecnico</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateTecnico();
          }}
        >
          <p className="title-modal">Nome Tecnico</p>
          <input
            type="text"
            placeholder="Digite o nome..."
            value={newTecnico.curso}
            onChange={(e) =>
              setNewTecnico({ ...newTecnico, curso: e.target.value })
            }
            required
          />
          <p className="title-modal">Tempo Total</p>
          <input
            type="number"
            placeholder="Digite o tempo..."
            value={newTecnico.tempoTotal}
            onChange={(e) =>
              setNewTecnico({ ...newTecnico, tempoTotal: e.target.value })
            }
            required
          />
          <p className="title-modal">Tipo do Curso</p>
          <select
            required
            value={newTecnico.filtro}
            onChange={(e) => handleNew(e)}
          >
            <option></option>
            {filtros.map((filtro) =>
              filtro.destino === 'CT' ? (
                <option key={filtro._id} value={filtro.nome}>
                  {filtro.nome} - {filtro.destino}
                </option>
              ) : null
            )}
          </select>
          <br />
          <button className="criador" type="submit">
            Criar
          </button>
        </form>
      </Modal>
    </>
  );
};

export default TecnicoManager;
