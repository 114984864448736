import axiosInstance from '../../utils/axiosConfig';
import { Usuario as UsuarioType } from '../../interfaces/Usuario/UsuarioProps';

export const fetchUsuarios = async (): Promise<UsuarioType[]> => {
  const response = await axiosInstance.get(`/user/`);
  return response.data.users;
};

export const createUsuario = async (usuario: {
  username: string;
  password: string;
  level: string;
}) => {
  await axiosInstance.post(`/user/`, usuario);
};

export const getUsuario = async (id: string) => {
  const response = await axiosInstance.get(`/user/${id}`);
  return response.data.user;
};

export const updateUsuario = async (id: string, usuario: UsuarioType) => {
  await axiosInstance.put(`/user/${id}`, usuario);
};

export const deleteUsuario = async (id: string) => {
  await axiosInstance.delete(`/user/${id}`);
};
