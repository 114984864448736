import { Feriado } from '../../interfaces/Feriado/FeriadoProps';

const verificarQualFeriado = (data: Date, feriados: Feriado[]): JSX.Element => {
  const dataFormatada = data.toISOString().split('T')[0];
  const feriadoEncontrado: Feriado | undefined = feriados.find(
    (feriado) => feriado.data === dataFormatada
  );

  if (!feriadoEncontrado) {
    return <span>Sem feriado</span>;
  }

  return <span>{feriadoEncontrado.nome}</span>;
};

export default verificarQualFeriado;
